import { Card, Skeleton, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useAppSelector } from "src/state/app.hooks";
import { reportSelector } from "src/state/report/report.reducer";
import TableComponent from "../DataTable";
import {
  ICategoryWiseAdmissionCount,
  IBranchWiseAllMissingAdmissionReport,
} from "./BranchWiseAllMissingAdmissionReport.model";
import { MissingDetailsData } from "src/services/students/student.model";

const BranchWiseAllMissingAdmissionReport = (
  props: IBranchWiseAllMissingAdmissionReport
) => {
  const { missingAdmissionReportStatics, loading } = props;
  const { getBranchWiseAllLabReport: allTableData } =
    useAppSelector(reportSelector);

  const columns: ColumnsType<MissingDetailsData> = [
    {
      title: "Branch Code",
      dataIndex: "branch_code",
      className: "as",
      key: "branch_code",
      align: "center",
    },
    {
      title: "Guardian Detail",
      dataIndex: "CategoryWiseAdmissionCount",
      key: "missing",
      align: "center",
      render: (record) => {
        return record.map(
          (admission: ICategoryWiseAdmissionCount) =>
            admission.category === "Guardian Detail" && (
              <Tooltip
                placement="topLeft"
                title={`Missing: ${admission.missing},
                Completed: ${admission.completed}`}
              >
                <>
                  <p>M: {admission.missing}</p>
                  <p>C: {admission.completed}</p>
                </>
              </Tooltip>
            )
        );
      },
    },
    {
      title: "Postal Communication",
      dataIndex: "CategoryWiseAdmissionCount",
      key: "postal",
      align: "center",
      render: (record) => {
        return record.map(
          (admission: ICategoryWiseAdmissionCount) =>
            admission.category === "Postal Communication" && (
              <Tooltip
                placement="topLeft"
                title={`Missing: ${admission.missing},
              Completed: ${admission.completed}`}
              >
                <>
                  <p>M: {admission.missing}</p>
                  <p>C: {admission.completed}</p>
                </>
              </Tooltip>
            )
        );
      },
    },
    {
      title: "Education & Profession",
      dataIndex: "CategoryWiseAdmissionCount",
      key: "education",
      align: "center",
      render: (record) => {
        return record.map(
          (admission: ICategoryWiseAdmissionCount) =>
            admission.category === "Education & Profession" && (
              <Tooltip
                placement="topLeft"
                title={`Missing: ${admission.missing},
              Completed: ${admission.completed}`}
              >
                <>
                  <p>M: {admission.missing}</p>
                  <p>C: {admission.completed}</p>
                </>
              </Tooltip>
            )
        );
      },
    },
    {
      title: "Document",
      dataIndex: "CategoryWiseAdmissionCount",
      key: "document",
      align: "center",
      render: (record) => {
        return record.map(
          (admission: ICategoryWiseAdmissionCount) =>
            admission.category === "Document" && (
              <Tooltip
                placement="topLeft"
                title={`Missing: ${admission.missing},
              Completed: ${admission.completed}`}
              >
                <>
                  <p>M: {admission.missing}</p>
                  <p>C: {admission.completed}</p>
                </>
              </Tooltip>
            )
        );
      },
    },
    {
      title: "Total",
      key: "total",
      align: "center",
      render: (record) => {
        return (
          <Tooltip
            placement="topLeft"
            title={`Missing: ${record.missingCount},
                Completed: ${record.completedCount}`}
          >
            <>
              <p>M: {record.missingCount}</p>
              <p>C: {record.completedCount}</p>
            </>
          </Tooltip>
        );
      },
    },
    {
      title: "Total Admission",
      dataIndex: "totalAdmissionCount",
      key: "totalAdmissionCount",
      align: "center",
    },
    {
      title: "Missing Admission Percentage",
      key: "missing_percentage",
      align: "center",
      render: (record) => {
        return (
          <>
            <p>
              {" "}
              {  record.totalAdmissionCount !== 0 ? ((record.missingCount / record.totalAdmissionCount) * 100).toFixed(2) || 0 : 0}% 
            </p>
          </>
        );
      },
    },
  ];

  return (
      <Card>
        <TableComponent
          columns={columns}
          dataSource={missingAdmissionReportStatics}
          loading={loading!}
        />
      </Card>
  );
};

export default BranchWiseAllMissingAdmissionReport;
