import { ISubCourseTopicRecord } from "src/pages/SubCourseTopic/SubCourseTopic.model";
import { config } from "src/utils";
import request from "../../lib/axios/request";
import { IApiResponse } from "../../utils/models/common";
import {
  ICreateQuestion,
  IEditQuestione,
  IGetQuestionTagsData,
  IGetSubCourseQuestionCountData,
  IQuestioneResponse,
  ISubCourseQuestionCsv,
} from "./subCourseQuestion.model";

class SubCourseQuestionService {
  ENDPOINT = config.baseApiMasters + "/exam-portal";

  public searchSubCourseQuestionData = async (
    data: any
  ): Promise<IApiResponse<IQuestioneResponse>> => {
    const url = `${this.ENDPOINT}/questions`;
    return request({
      url,
      method: "GET",
      params: data.searchParams ? data.searchParams : data,
    }).then((res) => {
      return res.data;
    });
  };

  public getSubCourseQuestionCountData = async (
    data: any
  ): Promise<IApiResponse<IGetSubCourseQuestionCountData>> => {
    const url = `${this.ENDPOINT}/get-questions-count/${data.subcourse_id}`;
    return request({
      url,
      method: "GET",
    }).then((res) => {
      return res.data;
    });
  };

  public createNewSubCourseQuestion = async (
    data: ICreateQuestion
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/exam-question`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public editSubCourseQuestionById = async (
    data: IEditQuestione
  ): Promise<IApiResponse<any>> => {
    const url = `${this.ENDPOINT}/exam-question/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };
  public uploadSubCourseQuestionCsvFile = async (
    newData: ISubCourseQuestionCsv
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${newData.id}/upload-bulk-questions-csv`;
    return request({ url, method: "POST", data: newData.body }).then((res) => {
      return res.data;
    });
  };

  public removeSubCourseQuestionById = async (id: number) => {
    const url = `${this.ENDPOINT}/exam-question/${id}`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public removeMultipleSubCourseQuestion = async (data: { id: [] }) => {
    const url = `${this.ENDPOINT}/multiple-exam-question`;
    return request({ url, method: "DELETE", data }).then((res) => {
      return res.data;
    });
  };

  public getQuestionTagsData = async (
    data: any
  ): Promise<IApiResponse<IGetQuestionTagsData>> => {
    const url = `${this.ENDPOINT}/subcourse/${data.subcourse_id}`;
    return request({
      url,
      method: "GET",
    }).then((res) => {
      return res.data;
    });
  };
}

export default new SubCourseQuestionService();
