import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { Suspense, useEffect, useState } from "react";
import { updateWindowWidth } from "./state/setting/setting.action";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "./utils/constants/ThemeSetting";
import { Amplify, Auth } from "aws-amplify";
import { config } from "./utils";
import loginService from "./services/login/login.service";
import { userProfile } from "./state/users/user.action";
import { AppDispatch } from "./state/app.model";
import { SidebarCollapseContext } from "./contexts/SidebarCollapseContext";
import { ability, convertAbility } from "./ability";
import { Common } from "./utils/constants/constant";
import { IUserRole } from "./services/user/user.model";
import {
  loginSelector,
  setIsBranchSelected,
} from "./state/Login/login.reducer";
import { rules } from "./utils/models/common";
import { userSelector } from "./state/users/user.reducer";
import { SidebarPermissionCodeContext } from "./contexts/sidebarPermissionCodeContext";
import { Helmet } from "react-helmet";
import { LoginPageMeta } from "./utils/constants/MetaInfoConstant";
import Loading from "./components/Loading";
import SubCourseTemplate from "./pages/SubCourseTemplate";
import ExpertSessionUpdateCard from "./pages/ExpertSessionUpdate";
import DegradeAndCancellRemarkReport from "./pages/Degrade&CancellStudentRemarkReport";
import ExpesrtSessionReport from "./pages/ExpertSessionsReport";
import InterviewSessionUpdateCard from "./pages/InterviewSessionUpdate";
import CRMLeadEnquiries from "./pages/CRMLeadEnquiries";
import MissingAdmissionAnalysis from "./pages/MissingAdmissionAnalysis";
import FacultyAvailable from "./pages/FacultyAvailable";
import CDGradeHistory from "./pages/CDGradeHistory";
import AssignBatchStudentDetailsList from "./pages/UserWiseBatch/AssignBatchStudentDetails";
import Counsellor from "./pages/Counsellor";
import Penalty from "./pages/PenaltyReceipt";
import PenaltyReceiptWithOutGst from "./components/PenaltyReceipt/PenaltyReceiptWithOutGst";
import SchoolWiseStudentCount from "./pages/SchoolWiseStudentCount";
import SubCourseQuestion from "./pages/SubCourseQuestion";
import QuestionBank from "./pages/SubcourseQuestionBank";
import TellyReport from "./pages/TellyReport";
import ExamMarks from "./pages/ExamMarks";
import Exams from "./pages/Exams";
import ExamBatchStudent from "./pages/ExamBatchStudent";
import ExamAnswerSheet from "./pages/ExamAnswerSheet";
import ExamResult from "./pages/ExamResult";
import ExamList from "./pages/ExamList";
import PcDetails from "./pages/PcDetails";
import ActiveStudentList from "./pages/ActiveStudentList";
const BatchCompletedDetails = React.lazy(
  () => import("./pages/UserWiseBatch/BatchCompletedDetails")
);
const CheckVersionModal = React.lazy(() => import("./components/CheckVersion"));
const AppSidebar = React.lazy(
  () => import("./components/containers/App/AppSidebar")
);
const Concession = React.lazy(() => import("./pages/Concession"));
const Topbar = React.lazy(() => import("./components/containers/Topbar"));
const NotFound = React.lazy(() => import("./pages/404NotFound/NotFound"));
const MainDashboard = React.lazy(() => import("./pages/MainDashboard"));
const BatchWiseStudentAttendanceUpdate = React.lazy(
  () => import("./pages/MyTeamBatches/BatchWiseStudentAttendanceUpdate")
);
const UnAssignBatchAdmission = React.lazy(
  () => import("./pages/ViewUnAssignAdmission")
);
const QRScanner = React.lazy(() => import("./components/QRScanner"));
const NotAccess = React.lazy(() => import("./pages/NotAccess"));
const StudentReportCard = React.lazy(() => import("./pages/StudentReportCard"));
const MyTeamBatches = React.lazy(() => import("./pages/MyTeamBatches"));
const StudentPresentAbsentDetails = React.lazy(
  () => import("./pages/MyTeamBatches/StudentPresentAbsentDetails")
);
const AdmissionMissingCRM = React.lazy(() => import("./pages/MissingCRM"));
const BranchConfig = React.lazy(() => import("./pages/Branch/BranchConfig"));
const FacultySingingSheet = React.lazy(
  () => import("./components/FacultySingingSheet")
);
const ViewPtmList = React.lazy(() => import("./pages/ViewPtmList"));
const StudentReportCardView = React.lazy(
  () => import("./pages/StudentReportCardView")
);
const CompanyVisitUpdateCard = React.lazy(
  () => import("./pages/CompanyVisitUpdateCard")
);

const Department = React.lazy(() => import("./pages/Department"));
const Permissions = React.lazy(() => import("./pages/Permissions"));
const SubDepartment = React.lazy(() => import("./pages/SubDepartment"));
const Course = React.lazy(() => import("./pages/Course"));
const DisplayStudentList = React.lazy(() => import("./pages/StudentList"));
const Project = React.lazy(() => import("./pages/Project"));
const StudentsAttendance = React.lazy(
  () => import("./pages/UserWiseBatch/StudentsAttendance")
);
const Attendence = React.lazy(
  () => import("./pages/UserWiseBatch/CheckAttendence")
);
const StudentDetailsList = React.lazy(
  () => import("./pages/UserWiseBatch/StudentDetailsList")
);
const StudentShiningSheet = React.lazy(
  () => import("./pages/UserWiseBatch/StudentShiningSheet")
);
const UserProfileContent = React.lazy(
  () => import("./pages/UserProfileContent")
);
const StudentsMarks = React.lazy(() => import("./pages/StudentsMarks"));
const UserConfig = React.lazy(() => import("./pages/UserConfig"));
const Login = React.lazy(() => import("./pages/Login"));
const User = React.lazy(() => import("./pages/User"));
const Income = React.lazy(() => import("./pages/Income"));
const ViewTemplate = React.lazy(
  () => import("./pages/SubCourseTemplate/ViewTemplate")
);
const Cheque = React.lazy(() => import("./pages/Cheque"));
const CourseAdmissionLetter = React.lazy(
  () => import("./components/CourseAdmissionLetter/CourseAdmissionLetter")
);
const FeesAdmissionLetter = React.lazy(
  () => import("./components/FeesAdmissionLetter/FeesAdmissionLetter")
);
const Area = React.lazy(() => import("./pages/Area"));
const InstallmentReport = React.lazy(
  () => import("./components/InstallmentReport/InstallmentRepost")
);
const SignInSheet = React.lazy(() => import("./components/SignInSheetTable"));
const UserWiseBatch = React.lazy(() => import("./pages/UserWiseBatch"));
const ViewMissingAdmission = React.lazy(
  () => import("./pages/ViewMissingAdmission")
);
const OutStandingIncome = React.lazy(() => import("./pages/OutStandingIncome"));
const TransferAdmission = React.lazy(() => import("./pages/TransferAdmission"));
const ReceiptComponent = React.lazy(
  () => import("./components/Receipt/Receipt")
);
const ExpenseMaster = React.lazy(() => import("./pages/ExpenseMaster"));
const OverdueIncome = React.lazy(() => import("./pages/OverdueIncome"));
const SubCategory = React.lazy(() => import("./pages/SubCategory"));
const SubCourse = React.lazy(() => import("./pages/SubCourse"));
const Category = React.lazy(() => import("./pages/Category"));
const AddAdmission = React.lazy(() => import("./pages/AddAdmission"));
const ViewAdmission = React.lazy(() => import("./pages/ViewAdmission"));
const SubCourseTopic = React.lazy(() => import("./pages/SubCourseTopic"));
const Expense = React.lazy(() => import("./pages/Expense"));
const Branch = React.lazy(() => import("./pages/Branch"));
const Batches = React.lazy(() => import("./pages/Batch"));
const MergeBatch = React.lazy(() => import("./pages/Batch/MergeBatch"));
const City = React.lazy(() => import("./pages/city"));
const Packages = React.lazy(() => import("./pages/Package"));
const Role = React.lazy(() => import("./pages/Role"));
const StudentAttendance = React.lazy(() => import("./pages/StudentAttendance"));
const Zone = React.lazy(() => import("./pages/Zone"));
const UserBranchSelection = React.lazy(
  () => import("./pages/Login/UserBranchSelection")
);
const StudentLists = React.lazy(() => import("./pages/StudentDetails"));
const Holidays = React.lazy(() => import("./pages/Holidays"));
const CompanyVisitReport = React.lazy(
  () => import("./pages/CompanyVisitReport")
);
const TopicAnalysis = React.lazy(() => import("./pages/TopicAnalysis"));
const ProjectAnalysis = React.lazy(() => import("./pages/ProjectAnalysis"));
const AssignLab = React.lazy(() => import("./pages/UserWiseBatch/AssignLab"));
const LabReport = React.lazy(() => import("./pages/LabReport"));
const CDGradeHistoryIndex = React.lazy(() => import("./pages/CDGradeHistory"));
const MainStatisticsDashboard = React.lazy(
  () => import("./pages/MainStatisticsDashboard")
);
const PenaltyReceipt = React.lazy(() => import("./components/PenaltyReceipt"));
const { Content, Footer } = Layout;

const storageID: string | null = localStorage.getItem("myStorageID");

const getContainerClass = (navStyle: string) => {
  switch (navStyle) {
    case NAV_STYLE_DARK_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_BELOW_HEADER:
      return "gx-container-wrap";
    case NAV_STYLE_ABOVE_HEADER:
      return "gx-container-wrap";
    default:
      return "";
  }
};

const getNavStyles = (navStyle: string) => {
  switch (navStyle) {
    // case NAV_STYLE_DEFAULT_HORIZONTAL :
    //   return <HorizontalDefault/>;
    // case NAV_STYLE_DARK_HORIZONTAL :
    //   return <HorizontalDark/>;
    // case NAV_STYLE_INSIDE_HEADER_HORIZONTAL :
    //   return <InsideHeader/>;
    // case NAV_STYLE_ABOVE_HEADER :
    //   return <AboveHeader/>;
    // case NAV_STYLE_BELOW_HEADER :
    //   return <BelowHeader/>;
    case NAV_STYLE_FIXED:
      return (
        <Suspense fallback={<Loading />}>
          <Topbar />
        </Suspense>
      );
    // case NAV_STYLE_DRAWER :
    //   return <Topbar/>;
    // case NAV_STYLE_MINI_SIDEBAR :
    //   return <Topbar/>;
    // case NAV_STYLE_NO_HEADER_MINI_SIDEBAR :
    //   return <NoHeaderNotification/>;
    // case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR :
    //   return <NoHeaderNotification/>;
    default:
      return null;
  }
};

Amplify.configure({
  Auth: {
    mandtorySignId: true,
    region: config.aws.region,
    userPoolId: config.aws.userPoolId,
    userPoolWebClientId: config.aws.userPoolWebClientId,
    cookieStorage: {
      // REQUIRED - Cookie domain
      // This should be the subdomain in production as
      // the cookie should only be present for the current site
      domain: config.aws.cookieDomain,
      // OPTIONAL - Cookie path
      path: "/",
      // OPTIONAL - Cookie expiration in days
      expires: 7,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating whether the cookie
      // transmission requires a secure protocol (https).
      // The cookie should be set to secure in production.
      secure: false,
    },
  },
  ssr: true,
});

function App() {
  let [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const { navStyle } = useSelector(({ settings }: any) => settings);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  let location = useLocation();
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const { isBranchSelected, userRoleId } = useSelector(loginSelector);
  const { userData } = useSelector(userSelector);
  const [isCode, setIsCode] = useState<string[]>([]);

  useEffect(() => {
    let code: string[] = [];

    userData?.data?.user_roles.map((x) => {
      if (x.id === userRoleId) {
        x?.role?.role_permissions?.map((y) => {
          let data = y?.page_action?.page?.page_code;

          if (!code?.includes(data)) {
            code.push(y?.page_action?.page?.page_code);
          }
        });
      }
    });
    setIsCode(code);
  }, [userData]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(updateWindowWidth(window.innerWidth));
    });
  }, [dispatch]);

  useEffect(() => {
    if (isLogin) {
      Auth.currentSession().then(() => {
        dispatch(userProfile({})).then((res) => {
          if (res.payload?.data.user_roles.length === 0) {
            dispatch(setIsBranchSelected(false));
            ability.update([]);
            navigate("/access-denied");
          } else {
            if (userRoleId) {
              const data = res.payload?.data.user_roles.find(
                (value: IUserRole) => value.id === userRoleId
              );
              if (!data) {
                if (res.payload?.data.user_roles.length > 0) {
                  dispatch(setIsBranchSelected(false));
                  navigate("/select-branch");
                }
              } else {
                const rules = convertAbility(
                  data.role.role_permissions
                ) as rules;
                ability.update(rules);
              }
            }
          }
        });
      });
    }
  }, [isLogin]);

  const checkAuth = (url = "") => {
    const isLoginPage = url.startsWith("/login");
    loginService
      .getCognitoUser({
        bypassCache: true,
      })
      .then(() => {
        setIsLogin(true);
        if (isLoginPage) {
          navigate("/");
        }
      })
      .catch(() => {
        setIsLogin(false);
        if (!isLoginPage) {
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    checkAuth(location.pathname);
  }, [location.pathname]);

  const handleVerify = (id: number) => {
    console.log("Verify Admission id", id);
  };
  const handleReject = (id: number) => {
    console.log("Reject Admission id", id);
  };

  return (
    <>
      <SidebarCollapseContext.Provider
        value={{
          sidebarCollapsed,
          setSidebarCollapsed,
        }}
      >
        {isLogin === false ? (
          <>
            <Helmet>
              <title>{LoginPageMeta.PAGE_TITLE}</title>
            </Helmet>
            <Routes>
              <Route
                path="/login"
                element={
                  <Suspense fallback={<Loading />}>
                    <Login />
                  </Suspense>
                }
              />
            </Routes>
          </>
        ) : (
          <>
            {isLogin === true && (
              <>
                <SidebarPermissionCodeContext.Provider
                  value={{
                    isCode,
                    setIsCode,
                  }}
                >
                  <Layout>
                    <Helmet>
                      <title>{LoginPageMeta.MAIN_TITLE}</title>
                    </Helmet>
                    <Suspense fallback={<div>Loading...</div>}>
                      <CheckVersionModal />
                    </Suspense>

                    <Routes>
                      <Route element={!isBranchSelected && <Outlet />}>
                        <Route
                          path="/select-branch"
                          element={
                            <Suspense fallback={<div>Loading...</div>}>
                              <UserBranchSelection />
                            </Suspense>
                          }
                        />
                        <Route
                          path="/access-denied"
                          element={
                            <Suspense fallback={<Loading />}>
                              <NotAccess />
                            </Suspense>
                          }
                        />
                      </Route>
                      <Route
                        path="/receipt/:invoice_id"
                        element={
                          <Suspense fallback={<Loading />}>
                            <ReceiptComponent />
                          </Suspense>
                        }
                      />
                      <Route
                        path="penalty_receipt/:invoice_id"
                        element={
                          <Suspense fallback={<Loading />}>
                            <PenaltyReceipt />
                            {/* without gst */}
                          </Suspense>
                        }
                      />
                      <Route
                        element={
                          ability.can(
                            Common.Actions.CAN_VIEW,
                            Common.Modules.ADMISSION
                              .ADMISSION_COURSE_ADMISSION_LETTER
                          ) &&
                          isCode?.includes(
                            Common.Modules.ADMISSION
                              .ADMISSION_COURSE_ADMISSION_LETTER
                          ) ? (
                            <Outlet />
                          ) : (
                            <NotFound />
                          )
                        }
                      >
                        <Route
                          path="/course-admission-letter/:admission_id"
                          element={
                            <Suspense fallback={<Loading />}>
                              <CourseAdmissionLetter />
                            </Suspense>
                          }
                        />
                      </Route>
                      <Route
                        element={
                          ability.can(
                            Common.Actions.CAN_VIEW,
                            Common.Modules.ADMISSION.ADMISSION_FEES_LETTER
                          ) &&
                          isCode?.includes(
                            Common.Modules.ADMISSION.ADMISSION_FEES_LETTER
                          ) ? (
                            <Outlet />
                          ) : (
                            <NotFound />
                          )
                        }
                      >
                        <Route
                          path="/fees-admission-letter/:admission_id"
                          element={
                            <Suspense fallback={<Loading />}>
                              <FeesAdmissionLetter />
                            </Suspense>
                          }
                        />
                      </Route>
                      <Route
                        element={
                          ability.can(
                            Common.Actions.CAN_VIEW,
                            Common.Modules.ADMISSION
                              .ADMISSION_INSTALLMENTS_REPORT
                          ) &&
                          isCode?.includes(
                            Common.Modules.ADMISSION
                              .ADMISSION_INSTALLMENTS_REPORT
                          ) ? (
                            <Outlet />
                          ) : (
                            <NotFound />
                          )
                        }
                      >
                        <Route
                          path="/installment-report/:admission_id"
                          element={
                            <Suspense fallback={<Loading />}>
                              <InstallmentReport />
                            </Suspense>
                          }
                        />
                      </Route>
                      <Route>
                        <Route
                          path="/student-report-card-view/:id"
                          element={
                            <Suspense fallback={<Loading />}>
                              <StudentReportCardView />
                            </Suspense>
                          }
                        />
                      </Route>
                    </Routes>
                  </Layout>
                  {(location?.pathname?.includes("receipt") ||
                    location?.pathname?.includes("student-report-card-view") ||
                    location?.pathname?.includes("course-admission-letter") ||
                    location?.pathname?.includes("fees-admission-letter") ||
                    location?.pathname?.includes("installment-report")) ===
                    false && (
                    <Layout className="gx-app-layout">
                      <Suspense fallback={<Loading />}>
                        <AppSidebar navStyle={navStyle} />
                      </Suspense>

                      <Layout>
                        {getNavStyles(navStyle)}
                        <Content
                          className={`gx-layout-content ${getContainerClass(
                            navStyle
                          )} `}
                        >
                          <div className="gx-main-content-wrapper">
                            <Routes>
                              <Route element={isBranchSelected && <Outlet />}>
                                {isCode.length > 0 && (
                                  <>
                                    <Route
                                      path="/"
                                      element={
                                        <Suspense fallback={<Loading />}>
                                          <MainDashboard />
                                        </Suspense>
                                      }
                                    />
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.DASHBOARD
                                            .DASHBOARD_REPORTED_COMPANY_VISIT_LIST
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.DASHBOARD
                                            .DASHBOARD_REPORTED_COMPANY_VISIT_LIST
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/cv-student-attendance/:id"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <StudentAttendance />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.DASHBOARD
                                            .DASHBOARD_REPORTED_EXPERT_SESSION_LIST
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.DASHBOARD
                                            .DASHBOARD_REPORTED_EXPERT_SESSION_LIST
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/es-student-attendance/:id"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <StudentAttendance />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.DASHBOARD
                                            .DASHBOARD_REPORTED_PLACEMENT_INTERVIEW_SESSION_LIST
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.DASHBOARD
                                            .DASHBOARD_REPORTED_PLACEMENT_INTERVIEW_SESSION_LIST
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/pis-student-attendance/:id"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <StudentAttendance />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      path="/qr"
                                      element={
                                        <QRScanner
                                          label={"Barcode Scan"}
                                          verify={(id: number) =>
                                            handleVerify(id)
                                          }
                                          reject={(id: number) =>
                                            handleReject(id)
                                          }
                                        />
                                      }
                                    />

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER.DEPARTMENTS
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER.DEPARTMENTS
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/departments"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Department />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER.ZONES
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER.ZONES
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/zones"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Zone />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.EXPENSE.CATEGORIES
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.EXPENSE.CATEGORIES
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/category"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Category />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.EXPENSE.SUBCATEGORIES
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.EXPENSE.SUBCATEGORIES
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/subcategory"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <SubCategory />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.EXPENSE.EXPENSE_MASTER
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.EXPENSE.EXPENSE_MASTER
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/expense-master"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <ExpenseMaster />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_USER_BATCH
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_USER_BATCH
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/batch"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <UserWiseBatch />
                                          </Suspense>
                                        }
                                      />

                                      <Route
                                        element={
                                          ability.can(
                                            Common.Actions.CAN_VIEW,
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_STUDENT_MARKS_VIEW
                                          ) &&
                                          isCode?.includes(
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_STUDENT_MARKS_VIEW
                                          ) ? (
                                            <Outlet />
                                          ) : (
                                            <NotFound />
                                          )
                                        }
                                      >
                                        <Route
                                          path="/batch/:batch_id/studentsmarks"
                                          element={
                                            <Suspense fallback={<Loading />}>
                                              <StudentsMarks />
                                            </Suspense>
                                          }
                                        />
                                      </Route>
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ACADEMIC.ACADEMIC_EXAM
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ACADEMIC.ACADEMIC_EXAM
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/batch/:batch_id/exams"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Exams />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ACADEMIC.ACADEMIC_EXAM
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ACADEMIC.ACADEMIC_EXAM
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="batch/:batch_id/:exam_paper_id/exambatchstudent"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <ExamBatchStudent />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ACADEMIC.ACADEMIC_EXAM
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ACADEMIC.ACADEMIC_EXAM
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="batch/:batch_id/:exam_paper_id/:exam_student_id/answersheet"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <ExamAnswerSheet />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_USER_BATCH_STUDENT_VIEW
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_USER_BATCH_STUDENT_VIEW
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="batch/:batch_id/StudentDetailsList"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <StudentDetailsList
                                              is_my_batch={false}
                                            />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_USER_BATCH_STUDENT_SIGNING_SHEET_VIEW
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_USER_BATCH_STUDENT_SIGNING_SHEET_VIEW
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/:batch_id/:admission_id/StudentShiningSheet"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <StudentShiningSheet />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER
                                            .BATCH_STUDENT_VIEW
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER
                                            .BATCH_STUDENT_VIEW
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/batches/:batch_id/DisplayStudentList"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <DisplayStudentList />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_UN_ASSIGN_BATCH_ADMISSION
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_UN_ASSIGN_BATCH_ADMISSION
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/un-assign-batch-admission"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <UnAssignBatchAdmission />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_USER_BATCH_FACULTY_SIGNING_SHEET_VIEW
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_USER_BATCH_FACULTY_SIGNING_SHEET_VIEW
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/batch/:batch_id/faculty"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <FacultySingingSheet
                                              is_my_batch={false}
                                            />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_USER_BATCH
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_USER_BATCH
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/batch"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <UserWiseBatch />
                                          </Suspense>
                                        }
                                      />
                                      <Route
                                        path="/batches/:batch_id/DisplayStudentList"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <DisplayStudentList />
                                          </Suspense>
                                        }
                                      ></Route>
                                      <Route
                                        element={
                                          ability.can(
                                            Common.Actions.CAN_VIEW,
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_USER_BATCH_ATTENDANCE_VIEW
                                          ) &&
                                          isCode?.includes(
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_USER_BATCH_ATTENDANCE_VIEW
                                          ) ? (
                                            <Outlet />
                                          ) : (
                                            <NotFound />
                                          )
                                        }
                                      >
                                        <Route
                                          path="batch/:batch_id/checkAttendence"
                                          element={
                                            <Suspense fallback={<Loading />}>
                                              <Attendence />
                                            </Suspense>
                                          }
                                        />
                                      </Route>
                                      <Route
                                        element={
                                          ability.can(
                                            Common.Actions.CAN_VIEW,
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_USER_BATCH_ATTENDANCE_VIEW
                                          ) &&
                                          isCode?.includes(
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_USER_BATCH_ATTENDANCE_VIEW
                                          ) ? (
                                            <Outlet />
                                          ) : (
                                            <NotFound />
                                          )
                                        }
                                      >
                                        <Route
                                          path="batch/:batch_id/shiningSheet/studentAttendence"
                                          element={
                                            <Suspense fallback={<Loading />}>
                                              <StudentsAttendance />
                                            </Suspense>
                                          }
                                        />
                                      </Route>
                                      <Route
                                        element={
                                          ability.can(
                                            Common.Actions.CAN_VIEW,
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_USER_BATCH_ATTENDANCE_VIEW
                                          ) &&
                                          isCode?.includes(
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_USER_BATCH_ATTENDANCE_VIEW
                                          ) ? (
                                            <Outlet />
                                          ) : (
                                            <NotFound />
                                          )
                                        }
                                      >
                                        <Route
                                          path="batch/:batch_id/shiningSheet"
                                          element={
                                            <Suspense fallback={<Loading />}>
                                              <SignInSheet />
                                            </Suspense>
                                          }
                                        />
                                      </Route>
                                      <Route
                                        element={
                                          ability.can(
                                            Common.Actions.CAN_VIEW,
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_BATCH_COMPLETED_VIEW
                                          ) &&
                                          isCode?.includes(
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_BATCH_COMPLETED_VIEW
                                          ) ? (
                                            <Outlet />
                                          ) : (
                                            <NotFound />
                                          )
                                        }
                                      >
                                        <Route
                                          path="batch/:batch_id/batch-completed-details"
                                          element={
                                            <Suspense fallback={<Loading />}>
                                              <BatchCompletedDetails
                                                is_my_batch={false}
                                              />
                                            </Suspense>
                                          }
                                        />
                                      </Route>

                                      <Route
                                        element={
                                          ability.can(
                                            Common.Actions.CAN_VIEW,
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_LAB_ASSIGN
                                          ) &&
                                          isCode?.includes(
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_LAB_ASSIGN
                                          ) ? (
                                            <Outlet />
                                          ) : (
                                            <NotFound />
                                          )
                                        }
                                      >
                                        <Route
                                          path="batch/:batch_id/lab-assign"
                                          element={
                                            <Suspense fallback={<Loading />}>
                                              <AssignLab />
                                            </Suspense>
                                          }
                                        />
                                      </Route>
                                    </Route>

                                    {/* my team batches */}

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_MY_TEAM_BATCHES_VIEW
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_MY_TEAM_BATCHES_VIEW
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/my-team-batches"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <MyTeamBatches />
                                          </Suspense>
                                        }
                                      />
                                      {/* my team batches student list show  */}
                                      <Route
                                        path="my-team-batches/:batch_id/StudentDetailsList"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <StudentDetailsList
                                              is_my_batch={true}
                                            />
                                          </Suspense>
                                        }
                                      />
                                      <Route
                                        element={
                                          ability.can(
                                            Common.Actions.CAN_VIEW,
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_ASSIGN_STUDENTS
                                          ) &&
                                          isCode?.includes(
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_ASSIGN_STUDENTS
                                          ) ? (
                                            <Outlet />
                                          ) : (
                                            <NotFound />
                                          )
                                        }
                                      >
                                        <Route
                                          path="my-team-batches/:batch_id/AssignBatchStudentDetailsList"
                                          element={
                                            <Suspense fallback={<Loading />}>
                                              <AssignBatchStudentDetailsList
                                                is_my_batch={true}
                                              />
                                            </Suspense>
                                          }
                                        />
                                      </Route>
                                      {/* my team batches faculty shining sheet show */}
                                      <Route
                                        element={
                                          ability.can(
                                            Common.Actions.CAN_VIEW,
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_USER_BATCH_FACULTY_SIGNING_SHEET_VIEW
                                          ) &&
                                          isCode?.includes(
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_USER_BATCH_FACULTY_SIGNING_SHEET_VIEW
                                          ) ? (
                                            <Outlet />
                                          ) : (
                                            <NotFound />
                                          )
                                        }
                                      >
                                        <Route
                                          path="my-team-batches/:batch_id/faculty"
                                          element={
                                            <Suspense fallback={<Loading />}>
                                              <FacultySingingSheet
                                                is_my_batch={true}
                                              />
                                            </Suspense>
                                          }
                                        />
                                      </Route>
                                      {/* my team batches faculty shining sheet topic wise Present Absent Student List */}
                                      <Route
                                        element={
                                          ability.can(
                                            Common.Actions.CAN_VIEW,
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_FACULTY_SIGNING_SHEET_MODIFY
                                          ) &&
                                          isCode?.includes(
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_FACULTY_SIGNING_SHEET_MODIFY
                                          ) ? (
                                            <Outlet />
                                          ) : (
                                            <NotFound />
                                          )
                                        }
                                      >
                                        <Route
                                          path="my-team-batches/:batch_faculty_attendance_id/faculty/StudentDetailsList"
                                          element={
                                            <Suspense fallback={<Loading />}>
                                              <StudentPresentAbsentDetails />
                                            </Suspense>
                                          }
                                        />
                                      </Route>
                                      <Route
                                        path="my-team-batches/:batch_id/faculty/student"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <FacultySingingSheet
                                              is_my_batch={true}
                                            />
                                          </Suspense>
                                        }
                                      />
                                      <Route
                                        element={
                                          ability.can(
                                            Common.Actions.CAN_VIEW,
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_FACULTY_SIGNING_SHEET_MODIFY
                                          ) &&
                                          isCode?.includes(
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_FACULTY_SIGNING_SHEET_MODIFY
                                          ) ? (
                                            <Outlet />
                                          ) : (
                                            <NotFound />
                                          )
                                        }
                                      >
                                        <Route
                                          path="my-team-batches/:batch_faculty_attendance_id/:batch_singing_sheet_id/:batch_id/faculty/shiningSheet/studentAttendence"
                                          element={
                                            <Suspense fallback={<Loading />}>
                                              <BatchWiseStudentAttendanceUpdate />
                                            </Suspense>
                                          }
                                        />
                                      </Route>

                                      {/* my team batch student marks show page */}
                                      <Route
                                        element={
                                          ability.can(
                                            Common.Actions.CAN_VIEW,
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_STUDENT_MARKS_VIEW
                                          ) &&
                                          isCode?.includes(
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_STUDENT_MARKS_VIEW
                                          ) ? (
                                            <Outlet />
                                          ) : (
                                            <NotFound />
                                          )
                                        }
                                      >
                                        <Route
                                          path="my-team-batches/:batch_id/studentsmarks"
                                          element={
                                            <Suspense fallback={<Loading />}>
                                              <StudentsMarks />
                                            </Suspense>
                                          }
                                        />
                                      </Route>

                                      {/* my team batch batch completed show page */}
                                      <Route
                                        element={
                                          ability.can(
                                            Common.Actions.CAN_VIEW,
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_BATCH_COMPLETED_VIEW
                                          ) &&
                                          isCode?.includes(
                                            Common.Modules.ACADEMIC
                                              .ACADEMIC_BATCH_COMPLETED_VIEW
                                          ) ? (
                                            <Outlet />
                                          ) : (
                                            <NotFound />
                                          )
                                        }
                                      >
                                        <Route
                                          path="my-team-batches/:batch_id/batch-completed-details"
                                          element={
                                            <Suspense fallback={<Loading />}>
                                              <BatchCompletedDetails
                                                is_my_batch={true}
                                              />
                                            </Suspense>
                                          }
                                        />
                                      </Route>
                                    </Route>

                                    {/* Report */}
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.REPORT
                                            .REPORT_COMPANY_VISIT
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.REPORT
                                            .REPORT_COMPANY_VISIT
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/company-visit-report"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <CompanyVisitReport />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.REPORT
                                            .REPORT_DEGRADE_AND_CANCELLATION_STUDENTS_REMARKS
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.REPORT
                                            .REPORT_DEGRADE_AND_CANCELLATION_STUDENTS_REMARKS
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/degrade-cancellation-student-remarks"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <DegradeAndCancellRemarkReport />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.REPORT
                                            .REPORT_EXPERT_SESSIONS
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.REPORT
                                            .REPORT_EXPERT_SESSIONS
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/expert-sessions-report"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <ExpesrtSessionReport />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.REPORT
                                            .REPORT_TOPIC_ANALYSIS
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.REPORT
                                            .REPORT_TOPIC_ANALYSIS
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/topic-analysis"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <TopicAnalysis />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.REPORT
                                            .REPORT_PROJECT_ANALYSIS
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.REPORT
                                            .REPORT_PROJECT_ANALYSIS
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/project-analysis"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <ProjectAnalysis />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.REPORT.LAB_REPORT
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.REPORT.LAB_REPORT
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/lab-report"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <LabReport />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.REPORT
                                            .MISSING_ADMISSION_ANALYSIS
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.REPORT
                                            .MISSING_ADMISSION_ANALYSIS
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/missing-admission-analysis"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <MissingAdmissionAnalysis />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.REPORT
                                            .FACULTY_AVAILABILITY_SCHEDULE
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.REPORT
                                            .FACULTY_AVAILABILITY_SCHEDULE
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/faculty-available"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <FacultyAvailable />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    {/* CD Grade History */}
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.REPORT.CD_GRADE_HISTORY
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.REPORT.CD_GRADE_HISTORY
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/cd-grade-history"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <CDGradeHistory />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    {/* Counsellor */}
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.REPORT
                                            .COUNSELLORS_INFORMATION
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.REPORT
                                            .COUNSELLORS_INFORMATION
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/counsellor"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Counsellor />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.REPORT
                                            .SCHOOL_WISE_STUDENT_COUNT
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.REPORT
                                            .SCHOOL_WISE_STUDENT_COUNT
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/school-wise-student-count"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <SchoolWiseStudentCount />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.REPORT.TELLY_REPORT
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.REPORT.TELLY_REPORT
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/tally-report"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <TellyReport />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.REPORT.EXAM_MARK_REPORT
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.REPORT.EXAM_MARK_REPORT
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/exam-marks"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <ExamMarks />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.REPORT
                                            .REPORT_ACTIVE_STUDENT_LIST_VIEW
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.REPORT
                                            .REPORT_ACTIVE_STUDENT_LIST_VIEW
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/active-student-list"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <ActiveStudentList />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    {/* Student List */}
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_STUDENT_LIST
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_STUDENT_LIST
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/student-details"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <StudentLists />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_EXAM_LISTING
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_EXAM_LISTING
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/exam-list"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <ExamList />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_EXAM_CREATE
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ACADEMIC
                                            .ACADEMIC_EXAM_CREATE
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/exam-result"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <ExamResult />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER.CITY
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER.CITY
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/city"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <City />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER.AREA
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER.AREA
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/area"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Area />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER.HOLIDAY
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER.HOLIDAY
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/holidays"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Holidays />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER.SUBDEPARTMENTS
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER.SUBDEPARTMENTS
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/subdepartments"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <SubDepartment />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_ASSIGN_PERMISSION,
                                          Common.Modules.USER_CONFIGURATION
                                            .ROLES
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.USER_CONFIGURATION
                                            .ROLES
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/permissions/:id"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Permissions />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.USER_CONFIGURATION
                                            .USERS
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.USER_CONFIGURATION
                                            .USERS
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/users"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <User />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER.COURSES
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER.COURSES
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/courses"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Course />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.USER_CONFIGURATION
                                            .ROLES
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.USER_CONFIGURATION
                                            .ROLES
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/roles"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Role />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER.SUBCOURSES
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER.SUBCOURSES
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/subcourses"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <SubCourse />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER.BATCHES
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER.BATCHES
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/batches"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Batches />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER
                                            .MASTER_MERGE_BATCH
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER
                                            .MASTER_MERGE_BATCH
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/batch/:batch_id/merge-batch"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <MergeBatch />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER.BRANCHES
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER.BRANCHES
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/branches"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Branch />
                                          </Suspense>
                                        }
                                      />
                                      <Route
                                        path="/branches/:id/branchConfig"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <BranchConfig />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      path="/get-pc-details"
                                      element={
                                        <Suspense fallback={<Loading />}>
                                          <PcDetails />
                                        </Suspense>
                                      }
                                    />
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.EXPENSE.EXPENSE_EXPENSE
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.EXPENSE.EXPENSE_EXPENSE
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/expense"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Expense />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER.PACKAGES
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER.PACKAGES
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/packages"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Packages />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_ADD,
                                          Common.Modules.ADMISSION.ADD_ADMISSION
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ADMISSION.ADD_ADMISSION
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/add-admission"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <AddAdmission />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ADMISSION.CONCESSION
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ADMISSION.CONCESSION
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/concession"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Concession />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ADMISSION
                                            .VIEW_ADMISSION
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ADMISSION
                                            .VIEW_ADMISSION
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/view-admission"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <ViewAdmission />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ADMISSION
                                            .TRANSFER_ADMISSION
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ADMISSION
                                            .TRANSFER_ADMISSION
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/transfer-admission"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <TransferAdmission />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER.SUBCOURSE_TOPIC
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER.SUBCOURSE_TOPIC
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/subcourses/:subcourse_id/topic"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <SubCourseTopic />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route>
                                      <Route
                                        path="/userprofile"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <UserProfileContent />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER
                                            .TEMPLATE_SHINING_SHEET
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER
                                            .TEMPLATE_SHINING_SHEET
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/subcourses/:subcourse_id/template"
                                        element={<SubCourseTemplate />}
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER
                                            .MASTER_SUBCOURSE_EXAM_QUESTION
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER
                                            .MASTER_SUBCOURSE_EXAM_QUESTION
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/subcourses/:subcourse_id/question"
                                        element={<SubCourseQuestion />}
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER
                                            .MASTER_QUESTION_BANK
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER
                                            .MASTER_QUESTION_BANK
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/subcourses/:subcourse_id/questionbank"
                                        element={<QuestionBank />}
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ADMISSION
                                            .OVERDUE_INCOME
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ADMISSION
                                            .OVERDUE_INCOME
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/overdue-income"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <OverdueIncome />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.MASTER
                                            .TEMPLATE_SHINING_SHEET
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.MASTER
                                            .TEMPLATE_SHINING_SHEET
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/subcourses/:subcourse_id/template/:template_id"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <ViewTemplate />
                                          </Suspense>
                                        }
                                      ></Route>
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ADMISSION
                                            .OUTSTANDING_INCOME
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ADMISSION
                                            .OUTSTANDING_INCOME
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/outstanding-income"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <OutStandingIncome />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ADMISSION.INCOME
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ADMISSION.INCOME
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/invoice-income"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Income />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ADMISSION
                                            .ADMISSION_CHEQUE_LIST
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ADMISSION
                                            .ADMISSION_CHEQUE_LIST
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/cheque"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Cheque />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route>
                                      <Route
                                        path="/SignInSheet"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <SignInSheet />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route>
                                      <Route
                                        path="/project"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Project />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_UPDATE,
                                          Common.Modules.USER_CONFIGURATION
                                            .USERS
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.USER_CONFIGURATION
                                            .USERS
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/userConfig/:id"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <UserConfig />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ADMISSION
                                            .ADMISSION_MISSING_ADMISSION
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ADMISSION
                                            .ADMISSION_MISSING_ADMISSION
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/view-missing-admission"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <ViewMissingAdmission />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ADMISSION
                                            .CRM_MISSING_DETAILS_ADMISSION
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ADMISSION
                                            .CRM_MISSING_DETAILS_ADMISSION
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/missing-crm"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <AdmissionMissingCRM />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ADMISSION
                                            .ADMISSION_CRM_LEAD_ENQUIRIES
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ADMISSION
                                            .ADMISSION_CRM_LEAD_ENQUIRIES
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/crm-lead-enquiries"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <CRMLeadEnquiries />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route>
                                      <Route
                                        path="/dashboard"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <MainDashboard />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route>
                                      <Route
                                        path="/statistics-dashboard"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <MainStatisticsDashboard />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.DASHBOARD
                                            .DASHBOARD_REPORTED_COMPANY_VISIT_LIST
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.DASHBOARD
                                            .DASHBOARD_REPORTED_COMPANY_VISIT_LIST
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/company-visit-card/:id"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <CompanyVisitUpdateCard />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.DASHBOARD
                                            .DASHBOARD_REPORTED_PLACEMENT_INTERVIEW_SESSION_LIST
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.DASHBOARD
                                            .DASHBOARD_REPORTED_PLACEMENT_INTERVIEW_SESSION_LIST
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/interview-session-card/:id"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <InterviewSessionUpdateCard />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.DASHBOARD
                                            .DASHBOARD_REPORTED_EXPERT_SESSION_LIST
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.DASHBOARD
                                            .DASHBOARD_REPORTED_EXPERT_SESSION_LIST
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/expert-session-card/:id"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <ExpertSessionUpdateCard />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <Route>
                                      <Route
                                        path="/student-report-card/:id"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <StudentReportCard />
                                          </Suspense>
                                        }
                                      />
                                    </Route>

                                    <Route>
                                      <Route
                                        path="/view-ptm-list"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <ViewPtmList />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                    <>
                                      <Route
                                        path="/un-assign-batch-admission"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <UnAssignBatchAdmission />
                                          </Suspense>
                                        }
                                      />
                                    </>
                                    <Route
                                      path="*"
                                      element={
                                        <Suspense fallback={<Loading />}>
                                          <NotFound />
                                        </Suspense>
                                      }
                                    />
                                    {/* change permission 1111*/}
                                    <Route
                                      element={
                                        ability.can(
                                          Common.Actions.CAN_VIEW,
                                          Common.Modules.ADMISSION
                                            .ADMISSION_PENALTY
                                        ) &&
                                        isCode?.includes(
                                          Common.Modules.ADMISSION
                                            .ADMISSION_PENALTY
                                        ) ? (
                                          <Outlet />
                                        ) : (
                                          <NotFound />
                                        )
                                      }
                                    >
                                      <Route
                                        path="/other-charges"
                                        element={
                                          <Suspense fallback={<Loading />}>
                                            <Penalty />
                                          </Suspense>
                                        }
                                      />
                                    </Route>
                                  </>
                                )}
                              </Route>
                            </Routes>
                          </div>
                        </Content>
                      </Layout>
                    </Layout>
                  )}
                </SidebarPermissionCodeContext.Provider>
              </>
            )}
          </>
        )}
      </SidebarCollapseContext.Provider>
    </>
  );
}

export default App;
