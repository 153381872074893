import { Button, Card, Col, Empty, Form, message, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ContainerHeader from "src/components/ContainerHeader";
import { AppDispatch } from "src/state/app.model";
import { searchSubCourseDataById } from "src/state/subCourse/subCourse.action";
import { PlusOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  bufferURLDecode,
  showTooltip,
  trimObject,
} from "src/utils/helperFunction";
import DrawerComponent from "src/components/Drawer";
import QuestionBankAdd from "./Add";
import TableComponent from "src/components/DataTable";
import { ColumnsType } from "antd/lib/table";
import ActionWrapper from "src/components/ActionWrapper";
import DeleteButtonWithConfirmComponent from "src/components/DeleteButtonWithConfirm";
import {
  createNewSubCourseQuestionBank,
  removeSubCourseQuestionBankById,
  searchSubCourseQuestionBankData,
  updateSubCourseQuestionBankData,
} from "src/state/SubCourseQuestionBank/subCourseQuestionBank.action";
import { useAppSelector } from "src/state/app.hooks";
import {
  questionBankSelector,
  clearRemoveMessage,
} from "src/state/SubCourseQuestionBank/subCourseQuestionBank.reducer";
import {
  IDeleteQuestioneBankData,
  IQuestionBankSelectedData,
  ISubCourseQuestionBankData,
  IUpdateQuestionBank,
} from "src/services/subCourseQuestionBank/subCourseQuestionBank.modal";
import ModalComponent from "src/components/Modal";
import { IQuestionBank } from "./QuestionBank.model";
import { Common, QuestionType, TopicType } from "src/utils/constants/constant";
import { Can } from "src/ability/can";

const QuestionBank = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [subCourseName, setSubCourseName] = useState<string>("");
  const subCourseQuestionBankState = useAppSelector(questionBankSelector);
  const [loading, setLoading] = useState(true);
  const { subcourse_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const [row, setRow] = useState<IUpdateQuestionBank>();
  const [data, setData] = useState<ISubCourseQuestionBankData[]>([]);
  const [disable, setDisable] = useState<boolean>(true);
  const [openCountModel, setOpenCountModel] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] =
    useState<IQuestionBankSelectedData>();

  useEffect(() => {
    if (subcourse_id) {
      dispatch(searchSubCourseQuestionBankData({ subcourse_id: subcourse_id }))
        .then((res: any) => {
          if (res?.payload.data) {
            setData(res.payload.data);
            setLoading(false);
          } else {
            setData([]);
          }
        })
        .catch(() => {
          setData([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [subcourse_id]);

  useEffect(() => {
    if (subCourseQuestionBankState.searchSubCourseQuestionBankData?.data) {
      setData(subCourseQuestionBankState.searchSubCourseQuestionBankData.data);
    }
  }, [subCourseQuestionBankState.searchSubCourseQuestionBankData.data]);

  useEffect(() => {
    dispatch(searchSubCourseDataById({ id: subcourse_id })).then((res: any) => {
      let headerName =
        res?.payload?.data?.name + " (" + res?.payload?.data?.code + ")";
      setSubCourseName(headerName);
    });
  }, [subcourse_id]);

  const handleEdit = (record: IUpdateQuestionBank) => {
    setRow(record);
  };

  const handleDelete = (record: IDeleteQuestioneBankData) => {
    dispatch(removeSubCourseQuestionBankById(record.id)).then((res) => {
      if (res.payload) {
        dispatch(
          searchSubCourseQuestionBankData({ subcourse_id: subcourse_id })
        ).then((res: any) => {
          if (res?.payload) {
            setLoading(false);
            setData(res.payload.data);
          } else {
            setData([]);
          }
        });
      }
    });
  };

  const updatePage = (values: IQuestionBank) => {
    setDisable(false);
    if (row) {
      let payload = null;
      payload = {
        ...values,
        id: row.id,
      };
      dispatch(updateSubCourseQuestionBankData(trimObject(payload))).then(
        (res) => {
          setDisable(true);
          if (res.payload) {
            setShow(false);
          }
          if (subcourse_id) {
            dispatch(
              searchSubCourseQuestionBankData({ subcourse_id: subcourse_id })
            );
          }
        }
      );
    } else {
      let payload = null;
      payload = {
        ...values,
        subcourse_id: Number(subcourse_id),
      };
      dispatch(createNewSubCourseQuestionBank(trimObject(payload))).then(
        (res) => {
          setDisable(true);
          if (res.payload) {
            setShow(false);
          }
          if (subcourse_id) {
            dispatch(
              searchSubCourseQuestionBankData({ subcourse_id: subcourse_id })
            );
          }
        }
      );
    }
  };

  useEffect(() => {
    if (subCourseQuestionBankState.createQuestionBank.message) {
      if (subCourseQuestionBankState.createQuestionBank.hasErrors) {
        message.error(subCourseQuestionBankState.createQuestionBank.message);
      } else {
        message.success(subCourseQuestionBankState.createQuestionBank.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [subCourseQuestionBankState.createQuestionBank.message]);

  useEffect(() => {
    if (subCourseQuestionBankState.removeById.message) {
      if (subCourseQuestionBankState.removeById.hasErrors) {
        message.error(subCourseQuestionBankState.removeById.message);
      } else {
        message.success(subCourseQuestionBankState.removeById.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [subCourseQuestionBankState.removeById.message]);

  useEffect(() => {
    if (subCourseQuestionBankState.updateById.message) {
      if (subCourseQuestionBankState.updateById.hasErrors) {
        message.error(subCourseQuestionBankState.updateById.message);
      } else {
        message.success(subCourseQuestionBankState.updateById.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [subCourseQuestionBankState.updateById.message]);

  const columns: ColumnsType<[]> = [
    {
      title: "No.",
      dataIndex: "id",
      sorter: false,
      align: "center",
      width: "5%",
      render: (item, record, index) => <>{index + 1}</>,
    },
    {
      title: "Question Bank Name",
      dataIndex: "name",
      width: "30%",
      render: (record) => {
        const maxLength = 75;
        return <span>{showTooltip(record, maxLength)}</span>;
      },
    },
    {
      title: "status",
      dataIndex: "status",
      width: "30%",
      render: (record) => {
        return record ? "Yes" : "No";
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      align: "center",
      render: (record) => {
        return (
          <>
            <ActionWrapper>
              <Can
                I={Common.Actions.CAN_UPDATE}
                a={Common.Modules.MASTER.MASTER_QUESTION_BANK}
              >
                <Button
                  className="btn_edit"
                  type="ghost"
                  icon={<EditOutlined />}
                  onClick={(e) => {
                    let showNew = !show;
                    setShow(showNew);
                    handleEdit(record);
                  }}
                >
                  Edit
                </Button>
              </Can>
              <Can
                I={Common.Actions.CAN_DELETE}
                a={Common.Modules.MASTER.MASTER_QUESTION_BANK}
              >
                <DeleteButtonWithConfirmComponent
                  title={"Are you sure want to delete Question Bank?"}
                  cancelText={"NO"}
                  okText={"YES"}
                  onDelete={() => handleDelete(record)}
                  children={"Delete"}
                />
              </Can>
              <Can
                I={Common.Actions.CAN_VIEW}
                a={Common.Modules.MASTER.MASTER_QUESTION_BANK}
              >
                <Button
                  className="btn_view"
                  type="ghost"
                  icon={<EyeOutlined />}
                  onClick={(e) => {
                    setOpenCountModel(true);
                    setSelectedQuestion(record);
                  }}
                >
                  View
                </Button>
              </Can>
            </ActionWrapper>
          </>
        );
      },
    },
  ];

  return (
    <>
        <Row
          align="middle"
          justify="space-between"
          gutter={24}
          className="mb-20"
        >
          <Col lg={12}>
            <ContainerHeader title={subCourseName} />
          </Col>
          <Col lg={12} className="text-align-right gx-mt-3 gx-mt-sm-0">
            <Can
              I={Common.Actions.CAN_ADD}
              a={Common.Modules.MASTER.MASTER_QUESTION_BANK}
            >
              <Button
                type="primary"
                onClick={() => {
                  setRow(undefined);
                  setShow(true);
                }}
                icon={<PlusOutlined />}
              >
                Question Bank
              </Button>
            </Can>
            <Button
              type="primary"
              onClick={() => {
                const url = searchParams.get("r");
                if (url) {
                  const backURL = bufferURLDecode(url);
                  navigate(backURL);
                } else {
                  navigate(`/subcourses`);
                }
              }}
            >
              <i className="fa fa-arrow-left back-icon"></i>
              <span className="gx-d-none gx-d-sm-inline-block ">Back</span>
            </Button>
          </Col>
        </Row>

        <div className="filter" style={{ height: "auto" }}>
          {show && (
            <DrawerComponent
              className="create-new-questionbank"
              title={row ? "Edit Question Bank" : "Create Question Bank"}
              onClose={() => {
                setShow(false);
              }}
              visible={show}
              permission={disable}
              size={"large"}
              footer={true}
              label={row ? "Update" : "Submit"}
            >
              <QuestionBankAdd data={row} onSubmit={updatePage} />
            </DrawerComponent>
          )}
        </div>

        <Card className="rnw-card table-card">
          <TableComponent columns={columns} dataSource={data} loading={loading} />
        </Card>

        {openCountModel && (
          <ModalComponent
            className="show-studentList absent-studentList"
            title="Question List"
            showModal={openCountModel}
            onCancel={() => {
              setOpenCountModel(false);
            }}
            component={
              <>
                <Card className="table-card question-table">
                  <div>
                    <table
                      className="faculty-signing"
                      style={{ minWidth: "600px" }}
                    >
                      <thead>
                        <tr>
                          <th align="center" style={{ width: "3%" }}>
                            No.
                          </th>
                          <th>Question</th>
                          <th>Severity</th>
                          <th align="center">Marks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedQuestion &&
                        selectedQuestion.question_bank_questions.length > 0 ? (
                          selectedQuestion?.question_bank_questions
                            ?.filter((question_bank_question) => {
                              if (
                                selectedQuestion.question_bank_type ===
                                TopicType.EXAM_THEORY
                              ) {
                                return (
                                  question_bank_question?.question
                                    ?.topic_type === TopicType.EXAM_THEORY
                                );
                              } else if (
                                selectedQuestion.question_bank_type ===
                                TopicType.EXAM_PRACTICAL
                              ) {
                                return (
                                  question_bank_question?.question
                                    ?.topic_type === TopicType.EXAM_PRACTICAL
                                );
                              }
                              return false;
                            })
                            ?.map((item, index: number) => {
                              const question = item.question;

                              return (
                                <tr className="sub-topic" key={question.id}>
                                  <td align="center">
                                    <span style={{ display: "inline-block" }}>
                                      {index + 1}
                                    </span>
                                  </td>
                                  <td>
                                    {question.question}{" "}
                                    {`(${question.question_type})`}
                                  </td>
                                  <td>{question.severity}</td>
                                  <td align="center">{question.marks}</td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr key="none">
                            <td colSpan={4} style={{ border: "none" }}>
                              <div>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </>
            }
            footer={[
              <div>
                <Button key="back" onClick={() => setOpenCountModel(false)}>
                  Cancel
                </Button>
              </div>,
            ]}
          ></ModalComponent>
        )}
    </>
  );
};

export default QuestionBank;
