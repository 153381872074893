import { Button, Col, DatePicker, Drawer, Form, Input, Select } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import FloatLabel from "src/components/Form/FloatLabel";
import { IBranchDetails } from "src/services/branch/branch.model";
import { IUserData } from "src/services/user/user.model";
import { IUserBatchData } from "src/services/userBatch/userBatch.model";
import { useAppSelector } from "src/state/app.hooks";
import { branchSelector } from "src/state/branch/branch.reducer";
import { userBatchSelector } from "src/state/userBatch/userBatch.reducer";
import { userSelector } from "src/state/users/user.reducer";
import { zoneSelector } from "src/state/zone/zone.reducer";
import { RoleType, UserStatus, ZoneType } from "src/utils/constants/constant";
import {
  GetSortOrder,
  facultyDataByBranchId,
  trimObject,
  userBranchWiseStatusCheck,
} from "src/utils/helperFunction";
import {
  ICDGradeFilterProps,
  IFilterFormData,
  IFilterFormFieldChange,
} from "./CDGradeHistory.model";
import { useSearchParams } from "react-router-dom";
import { batchSelector } from "src/state/batch/batch.reducer";
import { useWatch } from "antd/lib/form/Form";

const CDGradeFilter = (props: ICDGradeFilterProps) => {
  const { setFilterDrawer, filterDrawer } = props;
  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  const userState = useAppSelector(userSelector);
  const storageID: string | null = localStorage.getItem("myStorageID");
  const { RangePicker } = DatePicker;
  const [searchParams, setSearchParams] = useSearchParams();
  const [formValues, setFormValues] = useState({});
  const { Option } = Select;
  const branchState = useAppSelector(branchSelector);
  const zoneState = useAppSelector(zoneSelector);
  const { userData } = useAppSelector(userSelector);
  const batchState = useAppSelector(batchSelector);
  const currentUserRole = userData.data.user_roles.find(
    (role) => role.id === Number(storageID)
  )?.role;
  const currentUserZone = userData.data.user_roles.find(
    (role) => role.id === Number(storageID)
  )?.zone;
  const [dynamicBranch, setDynamicBranch] = useState<IBranchDetails[]>();
  const [dynamicFaculty, setDynamicFaculty] = useState<IUserData[]>();
  const branchData = branchState.branchesData.data.rows || [];
  const branchId = form.getFieldValue("branchIds");
  const zoneId = form.getFieldValue("zoneIds");

  const branchIds = useWatch("branchIds", form);
  const facultyId = useWatch("faculty_id", form);

  const rules = {
    gr_id: [
      { pattern: new RegExp(/^[0-9]+$/), message: "only Number Are Allowed" },
    ],
  };

  const batchData = useMemo(() => {
    return (
      batchState.batchData.data.rows
        .filter((branch) =>
          branchIds?.length > 0
            ? branchIds.includes(branch.branch_id.toString())
            : branch
        )
        .filter((branch) =>
          facultyId ? +facultyId === branch.user_id : branch
        ) || []
    );
  }, [batchState.batchData.data.rows, branchIds, facultyId]);

  const getFacultyData = useCallback(
    (branch_id: number[], zoneData?: number[]) => {
      const branchData =
        branchState.branchesData.data.rows.filter((branch) =>
          zoneData && zoneData.length > 0
            ? zoneData?.includes(branch.zone?.parent_id[0].id)
            : branch_id.includes(branch.id)
        ) || [];

      const facultyData = facultyDataByBranchId(
        branchData.map((branch) => branch.id),
        userState.usersData.data,
        branchState.branchesData.data
      );

      return userBranchWiseStatusCheck(
        branchData.map((branch) => branch.zone_id),
        facultyData,
        RoleType.FACULTY
      );
    },
    [branchState.branchesData.data, userState.usersData.data]
  );

  const setAllValueToFilter = () => {
    const currentZoneFaculty =
      branchState.branchesData.data.rows.length > 0
        ? getFacultyData(branchData?.map((branch) => branch?.id))
        : [];

    setDynamicFaculty(currentZoneFaculty);
    setDynamicBranch(branchState.branchesData.data.rows);
  };

  useEffect(() => {
    setAllValueToFilter();
  }, []);

  useEffect(() => {
    if (Object.keys(formValues).length > 0) {
      form.resetFields();
    }
  }, [formValues]);

  const onFinish = (values: IFilterFormData) => {
    if (values.updated_date && values.updated_date != null) {
      values.updated_date = values.updated_date.map((date) => {
        return moment(date).format("YYYY-MM-DD");
      });
    }

    let newDateData: string[] = [];
    let data = Object.fromEntries(new URLSearchParams(searchParams));
    if (values.updated_date) {
      newDateData = values.updated_date;
      let date = {
        start_date: newDateData[0],
        end_date: newDateData[1],
      };
      data = date;
    } else {
      values.start_date = null;
      values.end_date = null;
    }

    values = { ...data, ...values };
    const { updated_date, skip, ...rest } = values;

    let queryString = Object.entries(trimObject(rest))
      .filter(
        ([key, values]) =>
          values !== undefined &&
          values !== "" &&
          values !== null &&
          (Array.isArray(values) ? values.length > 0 : values)
      )
      .map(([key, values]) => key + "=" + encodeURIComponent(values as string))
      .join("&");
    setSearchParams(queryString);
    setFilterDrawer(false);
  };

  const dataConvertFromSearchParm = () => {
    const data = {
      ...setFormValues,
      start_date: "",
      end_date: "",
    };

    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;

      if (key === "branchIds") {
        Object.assign(data, {
          ["branchIds"]: value.split(","),
        });
      } else if (key === "zoneIds") {
        Object.assign(data, {
          ["zoneIds"]: value.split(","),
        });
      } else {
        Object.assign(data, {
          [key]: value,
        });
      }
    }

    if (data && data?.start_date && data?.end_date) {
      Object.assign(data, {
        ["updated_date"]: [moment(data?.start_date), moment(data?.end_date)],
      });
    }

    return data;
  };

  useEffect(() => {
    setFormValues(dataConvertFromSearchParm());
  }, [searchParams]);

  useEffect(() => {
    const formData = dataConvertFromSearchParm() as IFilterFormFieldChange;

    if (formData.zoneIds) {
      if (formData.zoneIds.length > 0) {
        const dynamicBranchData = branchState.branchesData.data.rows.filter(
          (branch) =>
            convertArrayType(formData.zoneIds, Number).includes(
              branch.zone?.parent_id[0].id
            )
        );
        setDynamicBranch(dynamicBranchData);
      } else {
        setDynamicBranch(branchState.branchesData.data.rows);
      }
      const facultyDataByBranch: IUserData[] = getFacultyData(
        branchState.branchesData.data.rows.map((branch) => branch.id),
        convertArrayType(formData.zoneIds, Number)
      );
      setDynamicFaculty(facultyDataByBranch);
    }

    if (formData.branchIds) {
      const facultyDataByBranch: IUserData[] = getFacultyData(
        formData.branchIds.length > 0
          ? formData.branchIds.map(Number)
          : branchState.branchesData.data.rows.map((branch) => branch.id)
      );
      setDynamicFaculty(facultyDataByBranch);
    }
  }, [formValues]);

  const convertArrayType = (
    array: (string | number)[],
    conversionFunction: NumberConstructor
  ) => {
    return array.map(conversionFunction);
  };

  const onChangeValue = (values: IFilterFormFieldChange) => {
    if (values.zoneIds) {
      form.setFieldValue("branchIds", undefined);
      form.setFieldValue("batch_id", undefined);
      form.setFieldValue("faculty_id", undefined);
      if (values.zoneIds.length > 0) {
        const dynamicBranchData = branchState.branchesData.data.rows.filter(
          (branch) =>
            convertArrayType(values.zoneIds, Number).includes(
              branch.zone?.parent_id[0].id
            )
        );
        setDynamicBranch(dynamicBranchData);
      } else {
        setDynamicBranch(branchState.branchesData.data.rows);
      }
      const facultyDataByBranch: IUserData[] = getFacultyData(
        branchState.branchesData.data.rows.map((branch) => branch.id),
        convertArrayType(values.zoneIds, Number)
      );
      setDynamicFaculty(facultyDataByBranch);
    }

    if (values.branchIds) {
      form.setFieldValue("batch_id", undefined);
      form.setFieldValue("faculty_id", undefined);

      const facultyDataByBranch: IUserData[] = getFacultyData(
        values.branchIds.length > 0
          ? values.branchIds.map(Number)
          : branchState.branchesData.data.rows.map((branch) => branch.id)
      );
      setDynamicFaculty(facultyDataByBranch);
    }

    if (values.faculty_id) {
      form.setFieldValue("batch_id", undefined);
    }
  };

  const handleCancel = () => {
    setFilterDrawer(false);
  };

  const onFilterFieldReset = () => {
    setSearchParams({});
    setFormValues({});
    setTimeout(() => form.resetFields());
  };

  return (
    <div className="filter" style={{ height: "auto" }}>
      <Drawer
        className="filter-drawer"
        title="CD Grade History Filter"
        width="1000"
        visible={filterDrawer}
        onClose={handleCancel}
        footer={[
          <div className="gx-d-flex gx-justify-content-center">
            <Button
              className="cancel-filter gx-mr-0"
              key="back"
              onClick={handleCancel}
            >
              <span className="gx-d-none gx-d-sm-block">Cancel</span>
              <i className="fa fa-close gx-d-sm-none"></i>
            </Button>
            <Button
              className="btn-apply-filter gx-mx-2"
              key="submit"
              type="primary"
              htmlType="submit"
              form="myForm"
            >
              Apply Filter
            </Button>
            <Button
              className="reset-filter"
              type="default"
              htmlType="reset"
              form="myForm"
            >
              <span className="gx-d-none gx-d-sm-block">Reset</span>
              <i className="fa fa-refresh gx-d-sm-none"></i>
            </Button>
          </div>,
        ]}
      >
        <Form
          id="myForm"
          onFinish={onFinish}
          form={form}
          onReset={onFilterFieldReset}
          initialValues={formValues}
          onValuesChange={onChangeValue}
        >
          <>
            <Col xs={24}>
              <FloatLabel
                label="Gr Id"
                placeholder="Enter Student Gr Id"
                name="gr_id"
              >
                <Form.Item name="gr_id" rules={rules.gr_id}>
                  <Input maxLength={9} />
                  {/* <InputNumber size="large" maxLength={9} /> */}
                </Form.Item>
              </FloatLabel>
            </Col>
            {/* This Filter only Public Zone  */}
            {currentUserZone?.type !== ZoneType.PRIVATE &&
              zoneState.zonesData.data.rows.length > 0 && (
                <Col xs={24}>
                  <FloatLabel
                    label="Zone"
                    placeholder="Select Zone"
                    name="zoneIds"
                  >
                    <Form.Item name="zoneIds">
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        allowClear
                        showSearch
                        showArrow
                        mode="multiple"
                        size="large"
                        filterOption={(value, option) =>
                          (option?.children?.toString() || "")
                            .toLowerCase()
                            .includes(value.toLowerCase().trim())
                        }
                      >
                        {zoneState.zonesData.data.rows
                          .filter(
                            (zone) =>
                              zone?.id === currentUserZone?.id ||
                              zone?.parent_id === currentUserZone?.id
                          )
                          .filter((zoneData) => zoneData.status === true)
                          .map((zoneDetails) => (
                            <Option value={zoneDetails.id?.toString()}>
                              {zoneDetails.code + "-" + zoneDetails.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </FloatLabel>
                </Col>
              )}

            {/* Only Show This Filter When User Role Type Is Faculty And Zone is Public Zone */}
            {!(currentUserRole?.type === RoleType.FACULTY) &&
              currentUserZone?.type !== ZoneType.PRIVATE && (
                <Col xs={24}>
                  <FloatLabel
                    label="Branch"
                    placeholder="Select Branch Code"
                    name="branchIds"
                  >
                    <Form.Item name="branchIds">
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        allowClear
                        showSearch
                        showArrow
                        mode="multiple"
                        size="large"
                        filterOption={(value, option) =>
                          (option?.children?.toString() || "")
                            .toLowerCase()
                            .includes(value.toLowerCase().trim())
                        }
                      >
                        {dynamicBranch &&
                          dynamicBranch
                            .filter((branch) => branch.status === true)
                            .map((branch) => (
                              <Option value={branch.id.toString()}>
                                {branch.code}
                              </Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </FloatLabel>
                </Col>
              )}

            {/* This Filter Is Show When User Role Type Is Not Faculty */}
            {currentUserRole?.type !== RoleType.FACULTY && (
              <Col xs={24}>
                <FloatLabel
                  label="Faculty Name"
                  placeholder="Select Faculty Name"
                  name="faculty_id"
                >
                  <Form.Item name="faculty_id">
                    <Select
                      allowClear
                      showSearch
                      onClear={() => form.setFieldValue("batch_id", undefined)}
                      filterOption={(input, option) =>
                        (option?.children?.toString() || "")
                          .toLowerCase()
                          .includes(input.toLowerCase().trim())
                      }
                    >
                      {dynamicFaculty &&
                        dynamicFaculty.map((fd: IUserData) => (
                          <Option
                            value={fd.id.toString()}
                            className={
                              fd.status === UserStatus.DISABLE
                                ? "inactive-status"
                                : ""
                            }
                          >{`${fd?.first_name}  ${fd?.last_name}`}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </FloatLabel>
              </Col>
            )}

            <Col xs={24}>
              <FloatLabel
                label="Batch Name"
                placeholder="Select Batch Name"
                name="batch_id"
              >
                <Form.Item name="batch_id">
                  <Select
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                    showSearch
                    showArrow
                    size="large"
                    filterOption={(value, option) =>
                      (option?.children?.toString() || "")
                        .toLowerCase()
                        .includes(value.toLowerCase().trim())
                    }
                  >
                    {batchData
                      .slice()
                      .sort(GetSortOrder("name", "ASC"))
                      .map((d) => {
                        return (
                          <Option value={d.id?.toString()}>{d.name}</Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>

            <Col xs={24}>
              <Form.Item
                name="updated_date"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <RangePicker
                  style={{ width: "100%" }}
                  name="updated_date"
                  getPopupContainer={(trigger) => trigger}
                  format={dateFormat}
                  ranges={{
                    Today: [moment(), moment()],
                    Week: [moment().startOf("week"), moment().endOf("week")],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    "This Year": [
                      moment().startOf("year"),
                      moment().endOf("year"),
                    ],
                  }}
                />
              </Form.Item>
            </Col>
          </>
        </Form>
      </Drawer>
    </div>
  );
};

export default CDGradeFilter;
