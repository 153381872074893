import { useCallback, useMemo, useState } from "react";
import { Button, Col, Modal, Popover, Row, Card, Tooltip } from "antd";
import { ICounsellerCard } from "./CounsellerCard.model";
import { PackageBranch } from "src/services/package/package.model";
import { ColumnName } from "../Counsellor.model";
import { currencyFormat, downloadFile } from "src/utils/helperFunction";
import {
  DownloadOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import TableComponent from "src/components/DataTable";
import ModalComponent from "src/components/Modal";
import SendMessage from "../SendMessage";

export const CounsellerCard = (props: ICounsellerCard) => {
  const { counsellerData } = props;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [setWhatsAppLinkModal, SetWhatsAppLinkModal] = useState<boolean>(false);
  const typePackageCheck = counsellerData?.type === ColumnName.PACKAGE;

  const handleSubcoursesList = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const tagsList = useMemo(() => {
    const tagsData = typePackageCheck
      ? counsellerData?.packageTags?.map((tag) => tag?.tag?.name?.trim())
      : counsellerData?.subcourseTags?.map((tag) => tag?.tag?.name?.trim());
    return tagsData || [];
  }, [counsellerData]);

  const eligibility = useMemo(
    () => counsellerData?.eligibility,
    [counsellerData]
  );

  const branchCodeList = useMemo(() => {
    return (
      (typePackageCheck
        ? counsellerData?.package_branches?.flatMap(
            (branchData: PackageBranch) => branchData?.branch?.code
          )
        : counsellerData?.course?.branch_courses?.flatMap(
            (branchData) => branchData?.branch?.code
          )) || []
    );
  }, [counsellerData]);

  const isTruncated = counsellerData?.eligibility?.length > 250;
  const eligibilityText = isTruncated
    ? `${counsellerData?.eligibility.substring(0, 250)}...`
    : counsellerData?.eligibility;

  const descriptionOpenFunction = (value: string) => {
    Modal.info({
      title: "Description",
      content: (
        <div
          className="remark-message fixHeight-model"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      ),
      onOk() {},
    });
  };

  const commonPopover = (
    dataArray: string[],
    title: string,
    showItemCount = 3
  ) => {
    const items = dataArray?.slice(0, showItemCount)?.join(", ");
    return dataArray?.length > showItemCount ? (
      <Popover
        getPopupContainer={(parent) => parent.parentElement as HTMLElement}
        content={
          <div>
            {dataArray.map((item, index) => (
              <p key={index} className="gx-mb-1">
                {item}
              </p>
            ))}
          </div>
        }
        title={title}
        placement="bottomLeft"
        trigger="click"
      >
        <button className="btn-link">{items}...</button>
      </Popover>
    ) : (
      <span style={{ opacity: "0.85" }}>{items}</span>
    );
  };

  const handleDownloadBrochure = useCallback(() => {
    if (counsellerData.brochure_link) {
      downloadFile(counsellerData.brochure_link);
    }
  }, [counsellerData]);

  return (
    <>
      <Card
        hoverable
        className="counseller-card"
        title={
          <Tooltip
            title={
              typePackageCheck ? "Click to explore the package" : "Subcourse"
            }
          >
            <a
              href="#"
              className="package-btn"
              onClick={() => typePackageCheck && handleSubcoursesList()}
            >
              {typePackageCheck ? "Package: " : "Subcourse: "}
              {counsellerData.name}
            </a>
          </Tooltip>
        }
      >
        <Row align={"middle"}>
          <Col xxl={21} lg={18} md={19} xs={24}>
            <Row
              gutter={[15, 0]}
              wrap
              className="gx-p-3 card-details erp-details gx-w-100"
            >
              <Col xs={24} xxl={18}>
                <Row gutter={[15, 24]} wrap className="gx-p-3">
                  <Col xs={12} sm={8} className="gx-fs-lg">
                    <p className="gx-mb-2">Branch Code</p>
                    {branchCodeList.length > 0 &&
                      commonPopover(
                        branchCodeList as string[],
                        "Branch Code",
                        1
                      )}
                  </Col>
                  <Col xs={12} sm={8} className="gx-fs-lg">
                    <p className="gx-mb-2">Fees</p>
                    <span style={{ opacity: "0.85" }}>
                      {currencyFormat(counsellerData?.total || 0)}
                    </span>
                  </Col>
                  <Col xs={12} sm={8} className="gx-fs-lg">
                    <p className="gx-mb-2">Month</p>
                    <span style={{ opacity: "0.85" }}>
                      {counsellerData?.duration}
                    </span>
                  </Col>
                  <Col xs={12} sm={8} className="gx-fs-lg">
                    <p className="gx-mb-2">Department</p>
                    <span style={{ opacity: "0.85" }}>
                      {counsellerData?.department?.name}
                    </span>
                  </Col>
                  <Col xs={12} sm={8} className="gx-fs-lg">
                    <p className="gx-mb-2">Installment</p>
                    <span style={{ opacity: "0.85" }}>
                      {counsellerData?.installment}
                    </span>
                  </Col>
                  <Col xs={12} sm={8} className="gx-fs-lg">
                    <p className="gx-mb-2">Suggestive Word</p>
                    {commonPopover(tagsList, "Tags")}
                  </Col>
                </Row>
              </Col>
              <Col xs={24} xxl={6} className="gx-fs-lg gx-p-3">
                <p className="gx-mb-2">Eligibility</p>
                {isTruncated ? (
                  <Popover
                    content={
                      <p className={`gx-mb-1 gx-p-2 hold-popover`}>
                        {eligibility}
                      </p>
                    }
                    placement="bottomRight"
                    title="Eligibility"
                    trigger="click"
                    getPopupContainer={(parent) =>
                      parent.parentElement as HTMLElement
                    }
                  >
                    <button
                      className="btn-link paragraph-break"                      
                    >
                      {eligibilityText}
                    </button>
                  </Popover>
                ) : (
                  <span style={{ opacity: "0.85"}} className="paragraph-break">{eligibility}</span>
                )}
              </Col>
            </Row>
          </Col>
          <Col xxl={3} lg={6} md={5} xs={24}>
            <div className="card-bottom more-options">
              {counsellerData.brochure_link && (
                <div className="action-icon">
                  <Button
                    icon={<FileTextOutlined />}
                    size="small"
                    type="primary"
                    onClick={() => {
                      Modal.confirm({
                        title: "Are you sure want to download the brochure?",
                        okText: "Yes",
                        cancelText: "No",
                        icon: <DownloadOutlined />,
                        onOk() {
                          counsellerData.brochure_link &&
                            handleDownloadBrochure();
                        },
                      });
                    }}
                  >
                    Brochure Link
                  </Button>
                </div>
              )}
              {counsellerData.description && (
                <div className="action-icon">
                  <Button
                    type="primary"
                    size="small"
                    icon={<InfoCircleOutlined />}
                    onClick={() =>
                      descriptionOpenFunction(counsellerData.description || "")
                    }
                  >
                    Description
                  </Button>
                </div>
              )}
              <div className="action-icon">
                <Button
                  type="primary"
                  size="small"
                  icon={<WhatsAppOutlined />}
                  onClick={() => SetWhatsAppLinkModal(true)}
                  style={{
                    padding: "0px 0px",
                  }}
                >
                  Send Message
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Card>

      {/* Subcourse Model */}
      {isModalVisible && (
        <ModalComponent
          showModal={isModalVisible}
          className=""
          title="Subcourses"
          onCancel={() => setIsModalVisible(false)}
          footer={null}
          component={
            <TableComponent
              columns={[
                {
                  title: "Subcourse",
                  dataIndex: ["subcourse", "name"],
                },
                {
                  title: "Fees",
                  dataIndex: ["subcourse", "total"],
                },
              ]}
              loading={false}
              dataSource={counsellerData?.package_subcourses || []}
            />
          }
        ></ModalComponent>
      )}
      {setWhatsAppLinkModal && (
        <ModalComponent
          className="whatsapp-modal"
          modelWidth={670}
          title={"Send Message"}
          showModal={setWhatsAppLinkModal}
          onCancel={() => {
            SetWhatsAppLinkModal(false);
          }}
          component={
            <SendMessage
              closeModal={() => {
                SetWhatsAppLinkModal(false);
              }}
              brocherLink={counsellerData.brochure_link ?? ""}
            />
          }
          footer={false}
        ></ModalComponent>
      )}
    </>
  );
};
