import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ICreateQuestion,
  IDeleteQuestioneData,
  IEditQuestione,
  IGetSubCourseQuestionCountData,
  ISubCourseQuestionCsv,
} from "src/services/subCourseQuestion/subCourseQuestion.model";
import subCourseQuestionService from "src/services/subCourseQuestion/subCourseQuestion.service";

export const searchSubCourseQuestionData = createAsyncThunk(
  "question/searchSubCourseQuestionData",
  async (data: URLSearchParams | {}) => {
    return subCourseQuestionService.searchSubCourseQuestionData(data);
  }
);

export const getSubCourseQuestionCountData = createAsyncThunk(
  "question/getSubCourseQuestionCountData",
  async (data: URLSearchParams | {}) => {
    return subCourseQuestionService.getSubCourseQuestionCountData(data);
  }
);

export const uploadSubCourseQuestionCsvFile = createAsyncThunk(
  "subCourseQuestion/uploadSubCourseQuestionCsvFile",
  async (data: ISubCourseQuestionCsv) => {
    return subCourseQuestionService.uploadSubCourseQuestionCsvFile(data);
  }
);

export const createNewSubCourseQuestion = createAsyncThunk(
  "question/createNewSubCourseQuestion",
  async (data: ICreateQuestion) => {
    return subCourseQuestionService.createNewSubCourseQuestion(data);
  }
);

export const editSubCourseQuestionById = createAsyncThunk(
  "question/editSubCourseQuestionById",
  async (data: IEditQuestione) => {
    return subCourseQuestionService.editSubCourseQuestionById(data);
  }
);

export const removeSubCourseQuestionById = createAsyncThunk(
  "question/removeSubCourseQuestionById",
  async (id: number) => {
    return subCourseQuestionService.removeSubCourseQuestionById(id);
  }
);

export const removeMultipleSubCourseQuestion = createAsyncThunk(
  "question/removeMultipleSubCourseQuestion",
  async (id: { id: [] }) => {
    return subCourseQuestionService.removeMultipleSubCourseQuestion(id);
  }
);

export const getQuestionTagsData = createAsyncThunk(
  "question/getQuestionTagsData",
  async (data: URLSearchParams | {}) => {
    return subCourseQuestionService.getQuestionTagsData(data);
  }
);
