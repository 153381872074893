import {
  Form,
  Button,
  Card,
  Col,
  Row,
  Skeleton,
  Modal,
  Badge,
  Drawer,
  Input,
  Select,
  InputNumber,
  message,
  Tooltip,
  Popover,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ContainerHeader from "src/components/ContainerHeader";
import TableComponent from "src/components/DataTable";
import { AppDispatch } from "src/state/app.model";
import {
  bufferURLDecode,
  showTooltip,
  trimObject,
} from "src/utils/helperFunction";
import {
  PlusOutlined,
  EditOutlined,
  FilterFilled,
  SwapOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import ActionWrapper from "src/components/ActionWrapper";
import DeleteButtonWithConfirmComponent from "src/components/DeleteButtonWithConfirm";
import DrawerComponent from "src/components/Drawer";
import SubCourseQuestionAdd from "./Add";
import { searchSubCourseDataById } from "src/state/subCourse/subCourse.action";
import FloatLabel from "src/components/Form/FloatLabel";
import { Option } from "antd/lib/mentions";
import {
  AnswerType,
  Common,
  Priority,
  Question,
  QuestionType,
  TopicType,
} from "src/utils/constants/constant";
import { useAppSelector } from "src/state/app.hooks";
import {
  createNewSubCourseQuestion,
  editSubCourseQuestionById,
  getQuestionTagsData,
  removeMultipleSubCourseQuestion,
  removeSubCourseQuestionById,
  searchSubCourseQuestionData,
  uploadSubCourseQuestionCsvFile,
} from "src/state/SubCourseQuestion/subCourseQuestion.action";
import {
  clearRemoveMessage,
  questionSelector,
} from "src/state/SubCourseQuestion/subCourseQuestion.reducer";
import {
  IDeleteQuestioneData,
  IEditQuestione,
  IGetQuestionTagsData,
  IQuestioneResponse,
  IQuestionsTag,
} from "src/services/subCourseQuestion/subCourseQuestion.model";
import { IMcqAnswer, ISubCourseQuestionRecord } from "./Question.model";
import QuestionViewList from "src/components/QuestionViewList";
import {
  searchSubCourseTopicData,
  uploadSubCourseTopicCsvFile,
} from "src/state/subCourseTopic/subCourseTopic.action";
import { Can } from "src/ability/can";
import { UploadOutlined } from "@ant-design/icons";
import { HeatmapLayer } from "@react-google-maps/api";
import { ability } from "src/ability";
import CommonFilter from "src/components/CommonFilter";
const SubCourseQuestion = () => {
  const subCourseQuestionState = useAppSelector(questionSelector);
  const [subCourseName, setSubCourseName] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const { subcourse_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [formValues, setFormValues] = useState({});
  const [form] = Form.useForm();
  const [show, setShow] = useState(false);
  const [row, setRow] = useState<IEditQuestione>();
  const [data, setData] = useState<IQuestioneResponse>();
  const [count, setCount] = useState<number>(0);
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [showContent, setShowContent] = useState(false);
  const [disable, setDisable] = useState<boolean>(true);
  const [showFile, setShowFile] = useState<boolean>(true);
  const [btn, setBtn] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [multiId, setMultiId] = useState<[]>([]);

  const navigate = useNavigate();
  const [page, setPage] = useState(
    searchParams.get("skip") && searchParams.get("take")
      ? Number(searchParams.get("skip")) / Number(searchParams.get("take")) + 1
      : 1
  );

  useEffect(() => {
    if (subcourse_id && showContent) {
      dispatch(
        searchSubCourseTopicData({
          noLimit: true,
          orderBy: "sequence",
          order: "ASC",
          subcourse_id: subcourse_id,
        })
      );
      dispatch(
        searchSubCourseQuestionData({
          noLimit: true,
          orderBy: "id",
          order: "ASC",
          subcourse_id: subcourse_id,
        })
      );
    }
  }, [showContent, subcourse_id]);

  useEffect(() => {
    dispatch(searchSubCourseDataById({ id: subcourse_id })).then((res: any) => {
      let headerName =
        res?.payload?.data?.name + " (" + res?.payload?.data?.code + ")";
      setSubCourseName(headerName);
    });
  }, [subcourse_id]);

  useEffect(() => {
    if (filterModalOpen) {
      dispatch(getQuestionTagsData({ subcourse_id: subcourse_id }));
    }
  }, [filterModalOpen]);

  useEffect(() => {
    if (subCourseQuestionState.SubCourseQuestionData.data && !showContent) {
      setData(subCourseQuestionState.SubCourseQuestionData.data);
    }
    if (searchParams.get("skip") && searchParams.get("take")) {
      setPage(
        searchParams.get("skip") && searchParams.get("take")
          ? Number(searchParams.get("skip")) /
              Number(searchParams.get("take")) +
              1
          : 1
      );
    }
  }, [subCourseQuestionState.SubCourseQuestionData.data]);

  useEffect(() => {
    if (Object.keys(formValues).length > 0) {
      form.resetFields();
    }
  }, [formValues]);

  const dataConvertFromSearchParm = () => {
    let data = {};
    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;
      if (value == "") {
        setSearchParams("");
        data = "";
      }
      if (key === "tag_ids") {
        Object.assign(data, {
          ["tag_ids"]: value.split(","),
        });
      } else {
        Object.assign(data, {
          [key]: value,
        });
      }
    }
    Object.assign(data, { subcourse_id: subcourse_id });
    return data;
  };

  useEffect(() => {
    if (searchParams) {
      const data = {
        ...setFormValues,
        ...dataConvertFromSearchParm(),
      };
      for (const entry of Array.from(searchParams.entries())) {
        const [key, value] = entry;
        if (value !== "") {
          if (key === "tag_ids") {
            Object.assign(data, {
              ["tag_ids"]: value.split(","),
            });
          } else {
            Object.assign(data, {
              [key]: value,
            });
          }
        }
      }
      Object.assign(data, { subcourse_id: subcourse_id });
      setFormValues(data);
    }
  }, [searchParams]);

  useEffect(() => {
    if (subcourse_id && !showContent) {
      dispatch(searchSubCourseQuestionData(dataConvertFromSearchParm())).then(
        (res) => {
          if (res?.payload) {
            setLoading(false);
          }
        }
      );
    }
  }, [searchParams, showContent]);

  useEffect(() => {
    let sum = 0;
    const data = Object.fromEntries(new URLSearchParams(searchParams));
    for (const [key, value] of Object.entries(data)) {
      if (
        key !== "orderBy" &&
        key !== "order" &&
        key !== "skip" &&
        key !== "take" &&
        key !== "r" &&
        key !== "subcourse_id" &&
        key !== "current_view" &&
        data[key] !== ""
      ) {
        sum += 1;
      }
    }
    setCount(sum);
  }, [window.location.search]);

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  const updateURLParams = (tab: string) => {
    const params = new URLSearchParams(window.location.search);
    params.set("current_view", tab);
    const updatedURL = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState(null, "", updatedURL);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const initialTab = params.get("current_view");
    setShowContent(initialTab === "question_view");
  }, []);

  useEffect(() => {
    const tab = showContent ? "question_view" : "table_view";
    updateURLParams(tab);
  }, [showContent]);

  const handleEdit = (record: IEditQuestione) => {
    setRow(record);
  };
  const change = () => {};

  const handleDelete = (record: IDeleteQuestioneData) => {
    dispatch(removeSubCourseQuestionById(record.id)).then((res) => {
      if (res.payload) {
        const searchData = Object.fromEntries(
          new URLSearchParams(searchParams)
        );
        Object.assign(searchData, { subcourse_id: subcourse_id });
        const { take, skip, ...rest } = searchData;
        data && data?.rows.length === 1 && take !== undefined
          ? setSearchParams({ take, ...rest })
          : setSearchParams(searchData);
        dispatch(searchSubCourseQuestionData(searchData));
        message.success(res.payload.data);
      }
    });
  };

  const updatePage = (values: ISubCourseQuestionRecord) => {
    setDisable(false);
    if (values?.mcq_answer?.length === 0) {
      values.mcq_answer = [];
    }
    if (row) {
      const data = {
        ...values,
        question_tags: values.question_tags?.map((tag) => ({
          name: tag,
          type: Question.QUESTION,
          subcourse_id: subcourse_id,
        })),
        id: row.id,
        subcourse_id: Number(subcourse_id),
      };

      dispatch(editSubCourseQuestionById(trimObject(data))).then((res) => {
        setDisable(true);
        if (res.payload) {
          setShow(false);
        }
        if (searchParams) {
          dispatch(searchSubCourseQuestionData(dataConvertFromSearchParm()));
        }
      });
    } else {
      let payload = null;
      payload = {
        ...values,
        subcourse_id: Number(subcourse_id),
        mcq_answer: values.mcq_answer?.map((answer: IMcqAnswer) => ({
          id: 0,
          ...answer,
        })),
        question_tags: values.question_tags?.map((tag) => ({
          name: tag,
          type: Question.QUESTION,
          subcourse_id: subcourse_id,
        })),
      };

      dispatch(createNewSubCourseQuestion(trimObject(payload))).then((res) => {
        setDisable(true);
        if (res.payload) {
          setShow(false);
        }
        if (searchParams) {
          dispatch(searchSubCourseQuestionData(dataConvertFromSearchParm()));
        }
      });
    }
  };

  useEffect(() => {
    if (subCourseQuestionState.removeById.message) {
      if (subCourseQuestionState.removeById.hasErrors) {
        message.error(subCourseQuestionState.removeById.message);
      } else {
        message.success(subCourseQuestionState.removeById.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [subCourseQuestionState.removeById.message]);

  useEffect(() => {
    if (subCourseQuestionState.removeMultipleSubcourseQuestions.message) {
      if (subCourseQuestionState.removeMultipleSubcourseQuestions.hasErrors) {
        message.error(
          subCourseQuestionState.removeMultipleSubcourseQuestions.message
        );
      } else {
        message.success(
          subCourseQuestionState.removeMultipleSubcourseQuestions.message
        );
      }
      dispatch(clearRemoveMessage());
    }
  }, [subCourseQuestionState.removeMultipleSubcourseQuestions.message]);

  useEffect(() => {
    if (subCourseQuestionState.editById.message) {
      if (subCourseQuestionState.editById.hasErrors) {
        message.error(subCourseQuestionState.editById.message);
      } else {
        message.success(subCourseQuestionState.editById.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [subCourseQuestionState.editById.message]);

  useEffect(() => {
    if (subCourseQuestionState.createQuestion.message) {
      if (subCourseQuestionState.createQuestion.hasErrors) {
        message.error(subCourseQuestionState.createQuestion.message);
      } else {
        message.success(subCourseQuestionState.createQuestion.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [subCourseQuestionState.createQuestion.message]);

  useEffect(() => {
    if (subCourseQuestionState.SubCourseQuesionCsvFile.message) {
      if (subCourseQuestionState.SubCourseQuesionCsvFile.hasErrors) {
        message.error(subCourseQuestionState.SubCourseQuesionCsvFile.message);
      } else {
        message.success(subCourseQuestionState.SubCourseQuesionCsvFile.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [subCourseQuestionState.SubCourseQuesionCsvFile.message]);

  const onFinish = (values: { [key: string]: string }) => {
    const queryString = Object.entries(trimObject(values))
      .filter(([key, values]) => values !== undefined && values !== "")
      .map(([key, values]) => key + "=" + encodeURIComponent(values as string))
      .join("&");
    setSearchParams(queryString);
    setFilterModalOpen(false);
  };

  const onReset = () => {
    setCount(0);
    setFormValues({});
    setSelectedRowKeys([]);
    setBtn(true);
    setTimeout(() => form.resetFields());
  };

  const handleUpload = (file: File) => {
    setShowFile(false);
    if (file.type === "text/csv") {
      const formData = new FormData();
      formData.append("file", file);
      setShowFile(false);
      dispatch(
        uploadSubCourseQuestionCsvFile({
          id: Number(subcourse_id),
          body: formData as unknown as File,
        })
      ).then((res) => {
        setShowFile(false);
        if (res.payload) {
          const searchData = Object.fromEntries(
            new URLSearchParams(searchParams)
          );
          Object.assign(searchData, { subcourse_id: subcourse_id });
          dispatch(searchSubCourseQuestionData(searchData)).then((res) => {
            if (res.payload) {
              setShowFile(false);
            }
          });
        }
      });
      return false;
    } else {
      setShowFile(false);
      message.error(`PLease Upload CSV file!`);
      return Upload.LIST_IGNORE;
    }
  };
  useEffect(() => {
    if (Object.keys(formValues).length > 0) {
      form.resetFields();
    }
  }, [formValues]);

  const onSelectChange = (newSelectedRowKeys: []) => {
    setSelectedRowKeys(newSelectedRowKeys);
    if (newSelectedRowKeys.length > 0) {
      setBtn(false);
      setMultiId(newSelectedRowKeys);
    } else {
      setBtn(true);
    }
  };

  const handleMultiDelete = () => {
    if (multiId.length > 0) {
      dispatch(removeMultipleSubCourseQuestion({ id: multiId })).then(() => {
        setBtn(true);
        const searchData = Object.fromEntries(
          new URLSearchParams(searchParams)
        );
        const { take, skip, ...rest } = searchData;
        if (take && skip) {
          if (Number(take) === Number(multiId?.length)) {
            setSearchParams({
              take,
              skip:
                data && data.rows && data.rows.length - multiId.length === 0
                  ? Number(skip) - Number(take) > 0
                    ? (Number(skip) - Number(take)).toString()
                    : "0"
                  : Number(skip) > 0
                  ? Number(skip).toString()
                  : "0",
              ...rest,
            });
          } else {
            if (data && data?.rows?.length - multiId.length === 0) {
              setSearchParams({
                take,
                skip:
                  Number(skip) - Number(take) > 0
                    ? (Number(skip) - Number(take)).toString()
                    : "0",
                ...rest,
              });
            }
          }
          const searchData = Object.fromEntries(
            new URLSearchParams(searchParams)
          );
          Object.assign(searchData, { subcourse_id: subcourse_id });
          dispatch(searchSubCourseQuestionData(searchData));
        } else {
          const searchData = Object.fromEntries(
            new URLSearchParams(searchParams)
          );
          Object.assign(searchData, { subcourse_id: subcourse_id });
          dispatch(searchSubCourseQuestionData(searchData));
        }
        setSelectedRowKeys([]);
      });
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    if (selectedRowKeys && selectedRowKeys.length > 0) {
      setBtn(false);
    } else {
      setBtn(true);
    }
  }, [selectedRowKeys]);

  const columns: ColumnsType<[]> = [
    {
      title: "No.",
      dataIndex: "id",
      sorter: false,
      align: "center",
      width: "5%",
      render: (item, record, index) => (
        <>{(page - 1) * Number(searchParams.get("take")) + index + 1}</>
      ),
    },
    {
      title: "Question",
      dataIndex: "question",
      width: "30%",
      render: (record) => {
        const maxLength = 75;
        return (
          <span style={{ textTransform: "none" }}>
            {showTooltip(record, maxLength)}
          </span>
        );
      },
    },
    {
      title: "Tags",
      dataIndex: "tags",
      sorter: false,
      render: (record) => {
        return (
          <>
            <div className="roles-details gx-d-flex ">
              {record?.map((tag: IQuestionsTag, index: number) => {
                if (index < 1) {
                  return (
                    <>
                      <p className="gx-mb-1">{tag?.name}</p>
                    </>
                  );
                }
              })}
              {record.length > 1 && (
                <>
                  <div className="gx-ml-2">
                    <Popover
                      content={
                        <p className="gx-mb-1 role-modal-details ">
                          {record?.map((tag: IQuestionsTag) => {
                            return <p>{tag?.name}</p>;
                          })}
                        </p>
                      }
                      placement="bottomRight"
                      title="Tags"
                      trigger="click"
                      getPopupContainer={(parent) =>
                        parent.parentElement as HTMLElement
                      }
                    >
                      <Button type="primary">More</Button>
                    </Popover>
                  </div>
                </>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "severity ",
      dataIndex: "severity",
      width: "30%",
      render: (record) => {
        return <span>{record}</span>;
      },
    },
    {
      title: "Marks",
      dataIndex: "marks",
      width: "30%",
      render: (record) => {
        const maxLength = 75;
        return <span>{showTooltip(record, maxLength)}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      align: "center",
      render: (record) => {
        return (
          <>
            <ActionWrapper>
              <Can
                I={Common.Actions.CAN_UPDATE}
                a={Common.Modules.MASTER.MASTER_SUBCOURSE_EXAM_QUESTION}
              >
                <Button
                  className="btn_edit"
                  type="ghost"
                  icon={<EditOutlined />}
                  onClick={(e) => {
                    let showNew = !show;
                    setShow(showNew);
                    handleEdit(record);
                  }}
                >
                  Edit
                </Button>
              </Can>
              <Can
                I={Common.Actions.CAN_DELETE}
                a={Common.Modules.MASTER.MASTER_SUBCOURSE_EXAM_QUESTION}
              >
                <DeleteButtonWithConfirmComponent
                  title={"Are you sure want to delete Question?"}
                  cancelText={"NO"}
                  okText={"YES"}
                  onDelete={() => handleDelete(record)}
                  children={"Delete"}
                  disabled={selectedRowKeys.includes(record.id)}
                />
              </Can>
            </ActionWrapper>
          </>
        );
      },
    },
  ];

  const onCancel = () => {
    setShow(false);
    setFilterModalOpen(false);
  };

  return (
    <>
      <div className="rnw-main-content">
          <Row
            align="middle"
            justify="space-between"
            gutter={24}
            className="mb-20"
          >
            <Col xxl={13} className="subcourse-title gx-mb-md-2 gx-mb-xl-0">
              <ContainerHeader title={subCourseName} />
            </Col>
            <Col xxl={11} xs={24} className="text-align-right subcourse-btns">
              <Row justify="end" gutter={[8, 8]} wrap>
                {!showContent && (
                  <>
                    <Col>
                      <Can
                        I={Common.Actions.CAN_DELETE}
                        a={Common.Modules.MASTER.MASTER_SUBCOURSE_EXAM_QUESTION}
                      >
                        <DeleteButtonWithConfirmComponent
                          className="gx-mt-0"
                          title={"Are you sure want to delete Questions?"}
                          okText={"YES"}
                          cancelText={"NO"}
                          disabled={btn}
                          onDelete={handleMultiDelete}
                          children={"Delete"}
                        />
                      </Can>
                    </Col>
                    <Col>
                      <Button
                        icon={<FilterFilled />}
                        onClick={() => setFilterModalOpen(true)}
                      >
                        Filter
                        <Badge count={count}></Badge>
                      </Button>
                    </Col>
                    <Col>
                      <Can
                        I={Common.Actions.CAN_ADD}
                        a={Common.Modules.MASTER.MASTER_SUBCOURSE_EXAM_QUESTION}
                      >
                        <Upload
                          accept=".csv"
                          beforeUpload={handleUpload}
                          showUploadList={showFile}
                        >
                          <Button
                            className="csv-btn upload-btn gx-w-100"
                            icon={<UploadOutlined />}
                          >
                            Upload CSV File
                          </Button>
                        </Upload>
                      </Can>
                    </Col>
                    <Col>
                      <Can
                        I={Common.Actions.CAN_ADD}
                        a={Common.Modules.MASTER.MASTER_SUBCOURSE_EXAM_QUESTION}
                      >
                        <Button
                          type="primary"
                          onClick={() => {
                            setRow(undefined);
                            setShow(true);
                          }}
                          icon={<PlusOutlined />}
                        >
                          Question
                        </Button>
                      </Can>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        onClick={() => {
                          const url = searchParams.get("r");
                          if (url) {
                            const backURL = bufferURLDecode(url);
                            navigate(backURL);
                          } else {
                            navigate(`/subcourses`);
                          }
                        }}
                      >
                        <i className="fa fa-arrow-left back-icon"></i>
                        <span className="gx-d-none gx-d-sm-inline-block ">
                          Back
                        </span>
                      </Button>
                    </Col>
                  </>
                )}
                <Col>
                  <Tooltip title={"View change"}>
                    <Button
                      type={showContent ? "primary" : "default"}
                      onClick={toggleContent}
                      icon={<SwapOutlined />}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <>
                <div className="filter" style={{ height: "auto" }}>
                  <Drawer
                    className="filter-drawer"
                    title="Question Filter"
                    width="1000"
                    visible={filterModalOpen}
                    onClose={() => {
                      setFilterModalOpen(false);
                    }}
                    footer={[
                      <div className="gx-d-flex gx-justify-content-center">
                        <Button
                          className="cancel-filter gx-mr-0"
                          key="back"
                          onClick={onCancel}
                        >
                          <span className="gx-d-none gx-d-sm-block">
                            Cancel
                          </span>
                          <i className="fa fa-close gx-d-sm-none"></i>
                        </Button>
                        <Button
                          className="btn-apply-filter gx-mx-2"
                          key="submit"
                          type="primary"
                          loading={loading}
                          htmlType="submit"
                          form="myForm"
                        >
                          Apply Filter
                        </Button>
                        <Button
                          className="reset-filter"
                          type="default"
                          htmlType="reset"
                          form="myForm"
                        >
                          <span className="gx-d-none gx-d-sm-block">Reset</span>
                          <i className="fa fa-refresh gx-d-sm-none"></i>
                        </Button>
                      </div>,
                    ]}
                  >
                  <CommonFilter
                        fielterData={(value) => onFinish(value)}
                        change={change}
                        reset={() => onReset()}
                      >
                      <Row gutter={24}>
                        <Col span={24}>
                          <FloatLabel
                            label="Tags"
                            placeholder="Select Tags"
                            name="tag_ids"
                          >
                            <Form.Item name="tag_ids">
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                mode="multiple"
                                showArrow
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                  (option?.children?.toString() || "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase().trim())
                                }
                              >
                                {subCourseQuestionState.questionTags.tags.map(
                                  (data: IGetQuestionTagsData) => (
                                    <Option value={data.id?.toString()}>
                                      {data.name}
                                    </Option>
                                  )
                                )}
                              </Select>
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                        <Col span={24}>
                          <FloatLabel
                            label="Severity Type"
                            placeholder="Select Priority Type"
                            name="severity"
                          >
                            <Form.Item name="severity">
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                size="large"
                                allowClear
                              >
                                <Option value={Priority.HIGH}>HIGH</Option>
                                <Option value={Priority.MEDIUM}>MEDIUM</Option>
                                <Option value={Priority.LOW}>LOW</Option>
                              </Select>
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                        <Col span={24}>
                          <FloatLabel
                            label="Topic Type"
                            placeholder="Select Topic Type"
                            name="topic_type"
                          >
                            <Form.Item name="topic_type">
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                size="large"
                                allowClear
                              >
                                <Option value={TopicType.EXAM_PRACTICAL}>
                                  EXAM PRACTICAL
                                </Option>
                                <Option value={TopicType.EXAM_THEORY}>
                                  EXAM THEORY
                                </Option>
                              </Select>
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                        <Col span={24}>
                          <FloatLabel
                            label="Question Type"
                            placeholder="Select Question Type"
                            name="question_type"
                          >
                            <Form.Item name="question_type">
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                size="large"
                                allowClear
                              >
                                <Option value={QuestionType.MCQ}>MCQ</Option>
                                <Option value={QuestionType.SUBJECTIVE}>
                                  SUBJECTIVE
                                </Option>
                              </Select>
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                        <Col span={24}>
                          <FloatLabel
                            label="Answer Type"
                            placeholder="Select Answer Type"
                            name="answer_type"
                          >
                            <Form.Item name="answer_type">
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                size="large"
                                allowClear
                              >
                                <Option value={AnswerType.URL}>URL</Option>
                                <Option value={AnswerType.UPLOAD_FILE}>
                                  UPLOAD FILE
                                </Option>
                              </Select>
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                        <Col span={24}>
                          <FloatLabel
                            label="Questions"
                            placeholder="Enter Questions"
                            name="question"
                          >
                            <Form.Item name="question">
                              <Input size="large" />
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                        <Col span={24}>
                          <FloatLabel
                            label="Enter Marks"
                            placeholder="Select Marks"
                            name="marks"
                          >
                            <Form.Item name="marks">
                              <InputNumber
                                type="number"
                                size="large"
                              />
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                      </Row>
                     </CommonFilter>
                  </Drawer>
                </div>
              </>
              <div className="filter" style={{ height: "auto" }}>
                {show && (
                  <DrawerComponent
                    className="assign-batch"
                    title={row ? "Edit Question" : "Create Question"}
                    onClose={() => {
                      setShow(false);
                    }}
                    visible={show}
                    permission={disable}
                    size={"large"}
                    width="70%"
                    footer={true}
                    label={row ? "Update" : "Submit"}
                  >
                    <SubCourseQuestionAdd data={row} onSubmit={updatePage} />
                  </DrawerComponent>
                )}
              </div>

              {!showContent && (
                <Card className="rnw-card question-card sub-departments">
                  <TableComponent
                    rowSelection={
                      ability.can(
                        Common.Actions.CAN_DELETE,
                        Common.Modules.MASTER.MASTER_SUBCOURSE_EXAM_QUESTION
                      ) && rowSelection
                    }
                    rowKey="id"
                    columns={columns}
                    dataSource={data?.rows || []}
                    loading={loading}
                    meta={data?.meta}
                  />
                </Card>
              )}

              {showContent && (
                <>
                  <QuestionViewList />
                </>
              )}
            </Col>
          </Row>
      </div>
    </>
  );
};

export default SubCourseQuestion;
