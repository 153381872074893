import { string } from "yargs";

export interface ITagsDetail {
  value: string;
  name: string;
}

export interface IFilterStateData {
  eligibilityFilter: string;
  tagsFilter: [] | number[];
  packageFilter: null | number;
  subCourseFilter: null | number;
  zoneIds: [] | number[];
  branchIds: [] | number[];
}
export interface IFilterData {
  eligibility?: string;
  tag_ids?: [] | number[];
  package_id?: null | number;
  subcourse_id?: null | number;
  allZone?: Boolean;
  branch_ids?: [] | number[];
}

export enum ColumnName {
  PACKAGE = "package",
  SUB_COURSE = "subcourses",
}
