import { Skeleton, Table, TableProps } from "antd";
import { useSearchParams } from "react-router-dom";
import PaginationComponent from "../Pagination";
import { IDataTableProps } from "./Datatable.model";

const TableComponent = (props: IDataTableProps) => {
  const {
    columns,
    dataSource,
    loading,
    meta,
    rowSelection,
    rowKey,
    rowClassName,
    summary,
    setSorter,
    lastElementRef
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const onChange: TableProps<any>["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    let data = {
      order:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === "descend"
          ? "DESC"
          : "",
      orderBy: sorter.field,
    };

    if (setSorter && data.order && data.orderBy) {      
      setSorter({order: data.order, orderBy: data.orderBy})
    }

    const queryString = Object.entries(data)
      .filter(([key, data]) => data !== undefined && data !== "")
      .map(([key, data]) => key + "=" + data)
      .join("&");
    if (searchParams.toString()) {
      const newUrl = new URLSearchParams(searchParams.toString());
      newUrl.set("skip", `${0}`);
      newUrl.set("orderBy", `${data.orderBy}`);
      data.order && newUrl.set("order", `${data.order}`);
      setSearchParams(newUrl);
    } else {
      setSearchParams(queryString);
    }
  };

  const locale = {
    emptyText: loading && <Skeleton paragraph={{ rows: 20 }} active={true} />,
  };

  return (
    <>
      <div className="table-bg">
        <Table
          className="table-striped"
          columns={columns}
          dataSource={!loading ? dataSource : []}
          // loading={loading}
          rowSelection={rowSelection}
          onChange={onChange}
          sortDirections={["ascend", "descend"]}
          pagination={false}
          rowKey={rowKey}
          rowClassName={rowClassName}
          summary={summary}
          locale={locale}
          onRow={(_, index) => {
            if (index === dataSource.length - 11 && lastElementRef) {
              return {
                ref: lastElementRef,
              };
            }
            return {
              onClick: () => {},
            };
          }}
        />
      </div>
      <div className="pagination">
        {meta && meta.itemCount > 0 && (
          <PaginationComponent
            meta={
              meta
                ? meta
                : {
                    take: 0,
                    itemCount: 0,
                    pageCount: 0,
                    hasPreviousPage: false,
                    hasNextPage: false,
                  }
            }
          />
        )}
      </div>
    </>
  );
};

export default TableComponent;
