import Table, { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Card, Skeleton } from "antd";
import {
  CategoryWiseAdmissionCount,
  MissingDetailsData,
} from "src/services/students/student.model";
import { IBranchWiseAllMissingAdmissionReport } from "../BranchWiseAllMissingAdmissionReport/BranchWiseAllMissingAdmissionReport.model";

const MissingAdmissionAnalysisTable = (
  props: IBranchWiseAllMissingAdmissionReport
) => {
  const { missingAdmissionReportStatics, loading } = props;

  const [tableData, setTableData] = useState<CategoryWiseAdmissionCount[]>();
  useEffect(() => {
    if (missingAdmissionReportStatics.length > 0) {
      missingAdmissionReportStatics.map((admission: MissingDetailsData) => {
        setTableData(admission.CategoryWiseAdmissionCount);
      });
    }
  }, [missingAdmissionReportStatics]);

  const branchWisePcAndLaptopList: ColumnsType<CategoryWiseAdmissionCount> = [
    {
      title: "Category",
      dataIndex: "category",
      align: "center",
    },
    {
      title: "Total Missing Count",
      dataIndex: "missing",
      align: "center",
    },
    {
      title: "Total Complete Count",
      dataIndex: "completed",
      align: "center",
    },
  ];

  const locale = {
    emptyText: loading && <Skeleton paragraph={{ rows: 10 }} active={true} />,
  };

  return (
      <Card className="missing-admission-card">
        <h4 className="gx-pb-2">Branch Wise Counts</h4>
        <div className="card-body">
          <div className="table-bg">
            <Table
              className="table-striped"
              columns={branchWisePcAndLaptopList}
              dataSource={tableData || []}
              sortDirections={["ascend", "descend"]}
              pagination={false}
              locale={locale}
            />
          </div>
        </div>
      </Card>
  );
};
export default MissingAdmissionAnalysisTable;
