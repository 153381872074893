import { Button, Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ContainerHeader from "src/components/ContainerHeader";
import { AppDispatch } from "src/state/app.model";
import { getSystemTrackerDetails } from "src/state/systemTracker/systemTracker.action";
import { clearSystemTrackerData, systemTrackerSelector } from "src/state/systemTracker/systemTracker.reducer";
import { bufferURLDecode } from "src/utils/helperFunction";
import ApexCharts from "react-apexcharts";
import { SystemTrackerPerformnce } from "src/utils/constants/constant";

import {
  IDataPoint,
  IPerformance,
  ISystemTrackerData,
} from "src/services/systemTracker/systemTracker.model";
import { ApexOptions } from "apexcharts";

const PcDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const state = location.state;
  const systemTrackerData = useSelector(systemTrackerSelector);
  const [data, setData] = useState<ISystemTrackerData[]>([]);

  useEffect(() => {
    const payload = {
      branch_code: state?.branch.code,
      pc_code: state?.name,
    };
    dispatch(getSystemTrackerDetails(payload));
  }, [searchParams]);

  useEffect(() => {
    return () => {
      dispatch(clearSystemTrackerData());
    };
  }, [location.pathname]);

  useEffect(() => {
    if (systemTrackerData.getSystemTrackerDetails.result) {
      setData(systemTrackerData.getSystemTrackerDetails.result.data);
    }
  }, [systemTrackerData.getSystemTrackerDetails.result]);

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  };

  const extractPerformanceData = (performance: IPerformance) => {
    return (
      performance &&
      Object.entries(performance).map(([timestamp, metrics]) => {
        const numericTimestamp = Number(timestamp);
        return {
          x: formatDate(numericTimestamp),
          y: metrics.CPU_Utilization_per,
        };
      })
    );
  };

  const extractMemoryData = (performance: IPerformance) => {
    return (
      performance &&
      Object.entries(performance).map(([timestamp, metrics]) => {
        const numericTimestamp = Number(timestamp);
        return {
          x: formatDate(numericTimestamp),
          y: metrics.Memory_Avaliable_MB,
        };
      })
    );
  };

  const extractDiskData = (performance: IPerformance) => {
    return (
      performance &&
      Object.entries(performance).map(([timestamp, metrics]) => {
        const numericTimestamp = Number(timestamp);
        return {
          x: formatDate(numericTimestamp),
          y: metrics.Disk_Active_Time_Per,
        };
      })
    );
  };

  const cpuData = extractPerformanceData(data[0]?.performance);
  const memoryData = extractMemoryData(data[0]?.performance);
  const diskData = extractDiskData(data[0]?.performance);

  const createChartOptions = (
    title: string,
    seriesData: IDataPoint[]
  ): {
    options: ApexOptions;
    series: { name: string; data: IDataPoint[] }[];
  } => ({
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: true,
          export: {
            csv: { filename: title },
            svg: { filename: title },
            png: { filename: title },
          },
        },
      },
      xaxis: {
        type: "datetime",
        categories: Array.isArray(seriesData)
          ? seriesData.map((data) => data.x)
          : [],
        labels: {
          hideOverlappingLabels: false,
          style: { fontSize: "12px" },
        },
      },
      stroke: { curve: "straight", width: 2 },
      dataLabels: { enabled: false },
      tooltip: { x: { format: "dd/MM/yy HH:mm" } },
    },
    series: [{ name: title, data: seriesData }],
  });

  const cpuOptions = createChartOptions(
    `${SystemTrackerPerformnce.CPU_UTILIZATIONPER} (%)`,
    cpuData
  );
  const memoryOptions = createChartOptions(
    `${SystemTrackerPerformnce.MEMORY_OPTIONS} (MB)`,
    memoryData
  );
  const diskOptions = createChartOptions(
    `${SystemTrackerPerformnce.DISK_ACTIVETIMEPER} (%)`,
    diskData
  );

  return (
    <div className="rnw-main-content">
      <Row align="middle" justify="space-between" gutter={24} className="mb-20">
        <Col xs={20} sm={20} xl={20}>
          <ContainerHeader
            title={`${state?.branch.name}(${state?.branch.code}) Config`}
          />
          <p className="gx-mt-1 gx-mb-1">{`PC : ${state?.name}`}</p>
        </Col>

        <Col xs={4} sm={4} xl={4} className="text-align-right">
          <Button
            type="primary"
            onClick={() => {
              const url = searchParams.get("r");
              if (url) {
                const backURL = bufferURLDecode(url);
                navigate(backURL);
              } else {
                navigate("/branches");
              }
            }}
          >
            <i className="fa fa-arrow-left back-icon"></i>{" "}
            <span className="gx-d-none gx-d-sm-inline-block">Back</span>
          </Button>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24}>
          <Card>
            <h4>{`${SystemTrackerPerformnce.CPU_UTILIZATIONPER}`}</h4>
            <ApexCharts
              options={cpuOptions.options}
              series={cpuOptions.series}
              type="area"
              height={350}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={24} xl={12} xxl={12}>
          <Card>
            <h4>{`${SystemTrackerPerformnce.MEMORY_OPTIONS}`}</h4>
            <ApexCharts
              options={memoryOptions.options}
              series={memoryOptions.series}
              type="area"
              height={350}
            />
          </Card>
        </Col>
        <Col xs={24} md={24} xl={12} xxl={12}>
          <Card>
            <h4>{`${SystemTrackerPerformnce.DISK_ACTIVETIMEPER}`}</h4>
            <ApexCharts
              options={diskOptions.options}
              series={diskOptions.series}
              type="area"
              height={350}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PcDetails;
