import { Card, Skeleton, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { facultyAvailableSlots } from "src/state/BranchInfrastructure/branchInfrastructure.action";
import { branchInfrastructureSelector } from "src/state/BranchInfrastructure/branchInfrastructure.reducer";
import { useAppSelector } from "src/state/app.hooks";
import { AppDispatch } from "src/state/app.model";
import { IProps, ITime } from "./FacultyAvailable.model";
import "./style.css";
import { FacultyAvailableSlotsEntity } from "src/services/BranchInfrastructure/branchInfrastructure.model";

const convertToMilliseconds = (time: string) => {
  const newTime = time.replace(".", ":");
  return moment.duration(newTime).asMilliseconds();
};

const FacultyAvailableSlot = (props: IProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const { isHo, masterSelectedZone, masterSelectedBranch } = props;

  const { facultyAvailableSlots: facultyAvailableSlotsData } = useAppSelector(
    branchInfrastructureSelector
  );

  useEffect(() => {
    dispatch(
      facultyAvailableSlots({
        zoneIds: masterSelectedZone,
        branchIds: masterSelectedBranch,
      })
    );
  }, [masterSelectedZone, masterSelectedBranch]);

  const timeSlots: ITime[] = Array.from({ length: 24 }, (_, i) => {
    if (i > 6 && i < 20) {
      return {
        start: convertToMilliseconds(`${i}:00`),
        end: convertToMilliseconds(`${i + 1}:00`),
      };
    }
    return null;
  }).filter((item): item is ITime => item !== null);

  const generateClassName = (index: number, className?: string) =>
    `${index % 2 === 0 ? "even-row" : "odd-row"} ${className}`;

  const GridCell = ({
    title,
    children,
    index,
    className,
  }: {
    title: string;
    children: React.ReactNode;
    index: number;
    className?: string;
  }) => (
    <Tooltip title={title}>
      <div className={generateClassName(index, className + " body-column")}>
        {children}
      </div>
    </Tooltip>
  );

  const GridCellWithOutTooltip = ({
    children,
    className,
    index,
  }: {
    children: React.ReactNode;
    className: string;
    index: number;
  }) => <div className={generateClassName(index, className)}>{children}</div>;

  const generateSlotStyle = (
    slot: FacultyAvailableSlotsEntity,
    timeSlots: ITime[]
  ) => {
    const totalDuration =
      timeSlots[timeSlots.length - 1].end - timeSlots[0].start;
    const slotDuration =
      convertToMilliseconds(slot.end_time) -
      convertToMilliseconds(slot.start_time);
    const slotStart =
      convertToMilliseconds(slot.start_time) - timeSlots[0].start;
    const innerWidth = (slotDuration / totalDuration) * 100;
    const left = (slotStart / totalDuration) * 100;

    return {
      width: `${innerWidth}%`,
      left: `${left}%`,
      backgroundColor: slot.type == "free" ? "#FFB8B8" : "#F5F0BB",
      boxShadow: "inset 0px 0px 2px 0px rgba(0, 0, 0, 0.3)",
    };
  };

  return (
    <Skeleton loading={facultyAvailableSlotsData.loading}>
      <Card>
        <h4>Faculty Availability Schedule</h4>
        <section
          className="faculty-available-slot-container"
          style={{
            gridTemplateColumns: `${
              isHo ? "150px 265px auto 150px" : "265px auto 150px"
            }`,
          }}
        >
          {/* herder */}
          {isHo && <div className="header-column">Branch</div>}
          <div className="header-column">Faculty</div>
          <div className="header-column-time">
            {timeSlots?.map((slot, mainIndex) => (
              <div
                key={slot.start + mainIndex}
                className="header-column"
                style={{
                  width: `${100 / timeSlots.length}%`,
                }}
              >
                {moment(mainIndex + 7, "HH").format("h A")}
              </div>
            ))}
          </div>
          <div className="header-column">Total Free Time</div>

          {/* body */}
          {facultyAvailableSlotsData.data?.map((faculty, index) => (
            <React.Fragment key={faculty?.id + index}>
              {isHo && (
                <GridCell title={faculty?.branch_code} index={index}>
                  {faculty?.branch_code}
                </GridCell>
              )}
              {faculty.name.length > 30 ? (
                <GridCell title={faculty.name} index={index}>
                  <span
                    className={faculty.underlined ? "gx-border-bottom-2" : ""}
                  >
                    {faculty.name}
                  </span>
                </GridCell>
              ) : (
                <GridCellWithOutTooltip className="body-column" index={index}>
                  <span
                    className={faculty.underlined ? "gx-border-bottom-2" : ""}
                  >
                    {faculty.name}
                  </span>
                </GridCellWithOutTooltip>
              )}

              <GridCellWithOutTooltip
                className="body-column-time"
                index={index}
              >
                {/* time border add */}
                {timeSlots?.map((slot, mainIndex) => (
                  <div
                    key={slot.start + mainIndex}
                    className="body-column-time-slot-border"
                  ></div>
                ))}
                {/* time slot */}
                {faculty?.facultyAvailableSlots?.map((slot, index) => {
                  return (
                    <Tooltip title={slot.name}>
                      <div
                        key={faculty.id + index}
                        className="body-column-time-slot"
                        style={generateSlotStyle(slot, timeSlots)}
                      >
                        {slot.name}
                      </div>
                    </Tooltip>
                  );
                })}
              </GridCellWithOutTooltip>

              <GridCell
                title={
                  Number.isInteger(parseFloat(String(faculty.totalFreeTime)))
                    ? String(faculty.totalFreeTime)
                    : parseFloat(String(faculty.totalFreeTime)).toFixed(2)
                }
                index={index}
                className="gx-text-center body-column"
              >
                {Number.isInteger(parseFloat(String(faculty.totalFreeTime)))
                  ? faculty.totalFreeTime
                  : parseFloat(String(faculty.totalFreeTime)).toFixed(2)}
              </GridCell>
            </React.Fragment>
          ))}
          {isHo && (
            <GridCellWithOutTooltip
              className="body-column"
              index={facultyAvailableSlotsData.data.length}
            >
              <></>
            </GridCellWithOutTooltip>
          )}

          <GridCellWithOutTooltip
            className="body-column"
            index={facultyAvailableSlotsData.data.length}
          >
            Total Faculty : {facultyAvailableSlotsData.data.length}
          </GridCellWithOutTooltip>

          <GridCellWithOutTooltip
            className="body-column"
            index={facultyAvailableSlotsData.data.length}
          >
            <></>
          </GridCellWithOutTooltip>

          <GridCellWithOutTooltip
            className="body-column gx-text-center"
            index={facultyAvailableSlotsData.data.length}
          >
            {facultyAvailableSlotsData.data.reduce(
              (acc, faculty) => acc + faculty.totalFreeTime,
              0
            )}
          </GridCellWithOutTooltip>
        </section>
      </Card>
    </Skeleton>
  );
};

export default FacultyAvailableSlot;
