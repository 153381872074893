import { useEffect, useMemo, useState } from "react";
import { Col, Row, Skeleton, Form, Select, DatePicker, Button, Badge, Drawer } from "antd";
import { useDispatch } from "react-redux";
import ContainerHeader from "src/components/ContainerHeader";
import { AppDispatch } from "src/state/app.model";
import { useAppSelector } from "src/state/app.hooks";
import { branchSelector } from "src/state/branch/branch.reducer";
import { searchBranchData } from "src/state/branch/branch.action";
import { userSelector } from "src/state/users/user.reducer";
import { zoneSelector } from "src/state/zone/zone.reducer";
import { AdmisionStatusType, ZoneType } from "src/utils/constants/constant";
import { searchZoneData } from "src/state/zone/zone.action";
import { useSearchParams } from "react-router-dom";
import moment, { Moment } from "moment";
import { missingAdmissionReportStaticsData } from "src/state/students/students.action";
import { studentsSelector } from "src/state/students/students.reducer";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";
import { MissingDetailsData } from "src/services/students/student.model";
import { IMissingAdmissionFilteredFormValue, IMissingCSVData } from "./MissingAdmissionAnalysis.model";
import BranchWiseAllMissingAdmissionReport from "src/components/BranchWiseAllMissingAdmissionReport";
import MissingAdmissionAnalysisCard from "src/components/MissingAdmissionAnalysisCard";
import MissingAdmissionAnalysisTable from "src/components/MissingAdmissionAnalysisTable";
import { FilterFilled , FileTextOutlined} from "@ant-design/icons";
import FloatLabel from "src/components/Form/FloatLabel";
import { trimObject } from "src/utils/helperFunction";

const { Option } = Select;

const MissingAdmissionAnalysis = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { RangePicker } = DatePicker;
  const dateFormat = "DD/MM/YYYY";
  const csvFileName = "MissingAdmissionAnalysisData.csv";
  const [searchParams, setSearchParams] = useSearchParams();
  const [count, setCount] = useState<number>(0);
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [formInitialData , setFormInitialData] = useState({});
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const branchState = useAppSelector(branchSelector);
  const studentState = useAppSelector(studentsSelector);
  const zoneState = useAppSelector(zoneSelector);
  const { userData } = useAppSelector(userSelector);
  const storageID: string | null = localStorage.getItem("myStorageID");
  const currentUserZone = userData.data.user_roles.find(
    (role) => role.id === Number(storageID)
  )?.zone;
  const IS_PRIVATE_ZONE = currentUserZone?.type === ZoneType.PRIVATE;

  const formZoneIds = Form.useWatch("zoneIds", form);
  const formAdmissionStatus = Form.useWatch("admission_status", form);
  const formCompleteDate = Form.useWatch("complete_date", form);

  useEffect(() => {
    dispatch(
      searchZoneData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        parent_id: currentUserZone?.id,
        type: ZoneType.PUBLIC,
        isFilter: true
      })
    );
    dispatch(
      searchBranchData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        isZoneOnly: true,
      })
    ).then(() => setLoading(false));
  }, [currentUserZone]);

  const branchData = useMemo(() => {
    return branchState.branchesData.data.rows
    .filter((branch) =>
      formZoneIds?.length > 0 ? formZoneIds?.map(Number) == branch.zone?.parent_id[0].id : branch
    ).filter((branch) => branch?.status === true) || [];
    
  },[branchState.branchesData.data.rows ,formZoneIds]);
      
  const fetchDataFromAPI = (params: IMissingAdmissionFilteredFormValue) => {
    const updatedParams = Object.fromEntries(Object.entries(params)?.filter(([key , value]) => Array.isArray(value) ? value?.length : value));
    dispatch(
      missingAdmissionReportStaticsData(updatedParams)
    );
  };

  const missingAdmissionReportStatics: MissingDetailsData[] =
    studentState.missingAdmissionReportStaticsData.data.filter((data)=> data.branch_id !== 0);

  const dataConvertFromSearchParm = () => {
    let data = {};
    const splitArray = ["zoneIds","branchIds","admission_status"];
    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;
      if (value == "") {
        setSearchParams("");
        data = "";
      } else if(splitArray?.includes(key)) {
        Object.assign(data, {
          [key]: value.split(","),
        });
      } else {
        Object.assign(data, {
          [key]: value,
        });
      }
    }
    return data;
  };

  const setQueryParams = (values:IMissingAdmissionFilteredFormValue) => {
    let queryString = Object.entries(trimObject(values))
    .filter(
      ([key, values]) =>
        values !== undefined &&
        values !== "" &&
        (Array.isArray(values) ? values.length > 0 : values)
    )
    .map(([key, values]) => key + "=" + encodeURIComponent(values as string))
    .join("&");
     setSearchParams(queryString);
  }

  useEffect(() => {
    if(currentUserZone && IS_PRIVATE_ZONE) {
      const data:IMissingAdmissionFilteredFormValue= {
        start_date : "",
        end_date : "",
        admission_status : []
      };
      if(formCompleteDate) {
        const date = formCompleteDate?.map((date:Moment) => moment(date).format("YYYY-MM-DD"))
        Object.assign(data, {
          ["start_date"]: date[0],
          ["end_date"]: date[1],
        });
      }
      if(formAdmissionStatus) {
        Object.assign(data, {
          ["admission_status"]: formAdmissionStatus,
        });
      }  
      setQueryParams(data);
    }
  },[formAdmissionStatus , formCompleteDate ]);

  useEffect(() => {
      const paramsData = dataConvertFromSearchParm();
      fetchDataFromAPI(paramsData)
  },[searchParams]);

  useEffect(() => {
    const formData:IMissingAdmissionFilteredFormValue = dataConvertFromSearchParm();
    if(formData.start_date && formData.end_date) {
        Object.assign(formData, {["complete_date"]: [moment(formData.start_date), moment(formData.end_date)],
      })
    }
    let { start_date, end_date, ...remaining } = formData;
    setFormInitialData(remaining);
  },[]);

  const columns = [
    {
      title: "Branch Code",
      key: "branch_code",
    },
    {
      title: "Guardian Missing Detail",
      key: "guardian_missing",
    },
    {
      title: "Guardian Completed Detail",
      key: "guardian_completed",
    },
    {
      title: "Postal Communication Missing Detail",
      key: "postal_missing",
    },
    {
      title: "Postal Communication Completed Detail",
      key: "postal_completed",
    },
    {
      title: "Education & Profession Missing Detail",
      key: "education_missing",
    },
    {
      title: "Education & Profession Completed Detail",
      key: "education_completed",
    },
    {
      title: "Document Missing Detail",
      key: "document_missing",
    },
    {
      title: "Document Completed Detail",
      key: "document_completed",
    },
    {
      title: "Total Missing Detail",
      key: "total_missing",
    },
    {
      title: "Total Completed Detail",
      key: "total_completed",
    },
    {
      title: "Total Admission",
      key: "total_admission",
    },
    {
      title: "Missing Admission Percentage",
      key: "missing_admission_percentage",
    },
  ];

  const getItemMissingCount = (item: MissingDetailsData, category: string) => {
    return (
      item?.CategoryWiseAdmissionCount?.find(
        (admission: { category: string }) => admission.category === category
      )?.missing || 0
    );
  };

  const getItemCompletedCount = (
    item: MissingDetailsData,
    category: string
  ) => {
    return (
      item?.CategoryWiseAdmissionCount?.find(
        (admission: { category: string }) => admission.category === category
      )?.completed || 0
    );
  };

  const CancelOrTerminateStudentCSVData: IMissingCSVData[] = [];
  let transformedItem: IMissingCSVData;

  if (
    missingAdmissionReportStatics &&
    missingAdmissionReportStatics.length > 0
  ) {
    for (const item of missingAdmissionReportStatics) {
      transformedItem = {
        branch_code: item.branch_code,
        guardian_missing: getItemMissingCount(item, "Guardian Detail"),
        guardian_completed: getItemCompletedCount(item, "Guardian Detail"),
        postal_missing: getItemMissingCount(item, "Postal Communication"),
        postal_completed: getItemCompletedCount(item, "Postal Communication"),
        education_missing: getItemMissingCount(item, "Education & Profession"),
        education_completed: getItemCompletedCount(
          item,
          "Education & Profession"
        ),
        document_missing: getItemMissingCount(item, "Document"),
        document_completed: getItemCompletedCount(item, "Document"),
        total_missing: item.missingCount,
        total_completed: item.completedCount,
        total_admission: item.totalAdmissionCount,
        missing_admission_percentage: `${
          (item.missingCount / item.totalAdmissionCount) * 100 || 0
        }%`,
      };
      CancelOrTerminateStudentCSVData.push(transformedItem);
    }
  }

  const onCancel = () => {
    setFilterModalOpen(false);
  };

  const onReset = () => {
    setSearchParams({});
    form.resetFields();
    setFormInitialData({});
    setCount(0);
  };

  const onFinish = (value : IMissingAdmissionFilteredFormValue) => {
    if(value.complete_date
    ) {
      const date = formCompleteDate?.map((date:Moment) => moment(date).format("YYYY-MM-DD"))
      Object.assign(value, {
        ["start_date"]: date[0],
        ["end_date"]: date[1],
      });
    };

    const {complete_date , ...rest} = value;
    setQueryParams(rest);
    setFilterModalOpen(false);
  }

  const handleZoneChange = () => {
    form.setFieldValue('branchIds',undefined)
  };

  useEffect(() => {
    if(!IS_PRIVATE_ZONE) {
      let sum = 0;
      const data = Object.fromEntries(new URLSearchParams(searchParams));
      for (const [key, value] of Object.entries(data)) {
        if (
          key !== "orderBy" &&
          key !== "order" &&
          key !== "skip" &&
          key !== "take"
        ) {
          sum += 1;
        }
      }
      setCount(sum); 
    }
  }, [window.location.search]);

  return (
    <>
      <div className="rnw-main-content missing-admission-analysis">
          <Row
            align="middle"
            gutter={[24,24]}
            justify="space-between"
            className="mb-20 gx-flex-wrap gx-align-items-center gx-justify-content-between gx-pt-1"

          >
            <Col  
              xs={24} 
              sm={IS_PRIVATE_ZONE ? 24 : 14 } 
              md={IS_PRIVATE_ZONE ? 24 : 14 } 
              lg={IS_PRIVATE_ZONE ? 8 : 14 }
              xl={IS_PRIVATE_ZONE ? 8 : 14 } 
              xxl={IS_PRIVATE_ZONE ? 8 : 14 } 
            className="gx-mb-md-1">
              <ContainerHeader title="Missing Admission Analysis" />
            </Col>

            { IS_PRIVATE_ZONE &&(
              <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16} >
                <Form
                  id="myForm"
                  form={form}
                  initialValues={{ ...formInitialData }}
                >
                  <Row gutter={[24,24]}  justify={"end"}> 
                    <Col xs={24}  sm={10} md={10} lg={10} xl={8} xxl={8} className="gx-pr-2">
                      <Form.Item name="complete_date">
                        <RangePicker
                          name="complete_date"
                          style={{width: "100%"}}
                          getPopupContainer={(trigger) => trigger}
                          format={dateFormat}
                          ranges={{
                            Today: [moment(), moment()],
                            Week: [
                              moment().startOf("week"),
                              moment().endOf("week"),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "This Year": [
                              moment().startOf("year"),
                              moment().endOf("year"),
                            ],
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24}  sm={10} md={11}  lg={9} xl={8}  xxl={8}>
                      <Form.Item name="admission_status">
                        <Select
                          getPopupContainer={(trigger) => trigger.parentNode}
                          allowClear
                          style={{width: "100%" }}
                          size="large"
                          placeholder="Select Admission Status"
                          showArrow
                          mode="multiple"
                          showSearch
                          filterOption={(input, option) =>
                            (option?.children?.toString() || "")
                              .toLowerCase()
                              .includes(input.toLowerCase().trim())
                          }
                        >
                          <Option value={AdmisionStatusType.ONGOING}>
                            Ongoing
                          </Option>
                          <Option value={AdmisionStatusType.PENDING}>
                            Pending
                          </Option>
                          <Option value={AdmisionStatusType.TRANSFER}>
                            Transfer
                          </Option>
                          <Option value={AdmisionStatusType.CANCELLED}>
                            Cancelled
                          </Option>
                          <Option value={AdmisionStatusType.HOLD}>Hold</Option>
                          <Option value={AdmisionStatusType.TERMINATED}>
                            Terminated
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col flex={"90px"} className="csv-download-btn">
                      {csvFileName && (
                        <CSVLink
                          filename={csvFileName}
                          data={CancelOrTerminateStudentCSVData}
                          headers={columns
                            .filter((colName) => colName.title !== "Action")
                            .map((colName) => {
                              return {
                                label: colName.title as string,
                                key: colName.key as string,
                              };
                            })}
                        >
                          <Button icon={<FileTextOutlined />}>CSV</Button>
                        </CSVLink>
                      )}
                    </Col>
                    </Row>
                </Form>
              </Col>
            )}

            { !(IS_PRIVATE_ZONE) &&
            (<Col  xs={24} sm={10} md={10} lg={10} xl={10} className="text-align-right">
              <Button
                icon={<FilterFilled />}
                onClick={() => setFilterModalOpen(true)}
              >
                Filter
                {<span>
                  <Badge count={count}></Badge>
                </span>}
              </Button>
              <CSVLink
                  className=""
                  filename={csvFileName}
                  data={CancelOrTerminateStudentCSVData}
                  headers={columns
                    .filter((colName) => colName.title !== "Action")
                    .map((colName) => {
                      return {
                        label: colName.title as string,
                        key: colName.key as string,
                      };
                    })}
                >
                    <Button
                       icon={<FileTextOutlined />}
                    >
                      CSV
                    </Button>
                </CSVLink>
            </Col>)}
          </Row>

          <Row>
            <Col span={24}>
              <div className="filter" style={{ height: "auto" }}>
                <Drawer
                  className="filter-drawer"
                  title="Missing Admission Analysis Filter"
                  width="1000"
                  visible={filterModalOpen}
                  onClose={() => {
                    setFilterModalOpen(false);
                  }}
                  footer={[
                    <div className="gx-d-flex gx-justify-content-center">
                      <Button
                        className="cancel-filter gx-mr-0"
                        key="back"
                        onClick={onCancel}
                      >
                        <span className="gx-d-none gx-d-sm-block">Cancel</span>
                        <i className="fa fa-close gx-d-sm-none"></i>
                      </Button>
                      <Button
                        className="btn-apply-filter gx-mx-2"
                        key="submit"
                        type="primary"
                        loading={loading}
                        htmlType="submit"
                        form="myForm"
                      >
                        Apply Filter
                      </Button>
                      <Button
                        className="reset-filter"
                        type="default"
                        htmlType="reset"
                        form="myForm"
                        onClick={() => onReset()}
                      >
                        <span className="gx-d-none gx-d-sm-block">Reset</span>
                        <i className="fa fa-refresh gx-d-sm-none"></i>
                      </Button>
                    </div>,
                  ]}
                >
                  <Form
                    id="myForm"
                    onFinish={onFinish}
                    form={form}
                    initialValues={{...formInitialData}}
                  >
                    <Row gutter={24}>
                      <Col xs={24}>
                        <FloatLabel
                          label="Select Zone"
                          placeholder="Select Zone"
                          name="zoneIds"
                        >
                          <Form.Item name="zoneIds">
                            <Select
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                              allowClear
                              showSearch
                              style={{ width: "100%", textAlign: "left" }}
                              showArrow
                              mode="multiple"
                              size="large"
                              filterOption={(value, option) =>
                                (option?.children?.toString() || "")
                                  .toLowerCase()
                                  .includes(value.toLowerCase().trim())
                              }
                              onChange={handleZoneChange}
                            >
                              {zoneState.zonesData.data.rows
                                .filter(
                                  (zone) =>
                                    zone?.id === currentUserZone?.id ||
                                    zone?.parent_id === currentUserZone?.id
                                )
                                .filter((z) => z.status === true)
                                .map((z) => (
                                  <Option value={z.id?.toString()}>
                                    {z.code + "-" + z.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col xs={24}>
                        <FloatLabel
                          label="Select Branch"
                          placeholder="Select Branch"
                          name="branchIds"
                        >
                          <Form.Item name="branchIds">
                            <Select
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                              allowClear
                              className="dash_input"
                              style={{
                                width: "100%",
                                textAlign: "left",
                              }}
                              showSearch
                              showArrow
                              mode="multiple"
                              size="large"
                              filterOption={(value, option) =>
                                (option?.children?.toString() || "")
                                  .toLowerCase()
                                  .includes(value.toLowerCase().trim())
                              }
                            >
                              {branchData
                                .filter((branch) => branch.status === true)
                                .map((branch) => (
                                  <Option value={branch.id?.toString()}>
                                    {branch.code}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col xs={24}>
                        <FloatLabel
                          label=""
                          placeholder=""
                          name="complete_date"
                        >
                          <Form.Item name="complete_date">
                            <RangePicker
                              style={{ width: "100%" }}
                              name="complete_date"
                              getPopupContainer={(trigger) => trigger}
                              format={dateFormat}
                              ranges={{
                                Today: [moment(), moment()],
                                Week: [
                                  moment().startOf("week"),
                                  moment().endOf("week"),
                                ],
                                "This Month": [
                                  moment().startOf("month"),
                                  moment().endOf("month"),
                                ],
                                "This Year": [
                                  moment().startOf("year"),
                                  moment().endOf("year"),
                                ],
                              }}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col xs={24}>
                        <FloatLabel
                          label="Admission Status"
                          placeholder="Select Admission Status"
                          name="admission_status"
                        >
                          <Form.Item name="admission_status">
                            <Select
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                              style={{ width: "100%", textAlign: "left" }}
                              allowClear
                              size="large"
                              showArrow
                              mode="multiple"
                              showSearch
                              filterOption={(input, option) =>
                                (option?.children?.toString() || "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase().trim())
                              }
                            >
                              <Option value={AdmisionStatusType.ONGOING}>
                                Ongoing
                              </Option>
                              <Option value={AdmisionStatusType.PENDING}>
                                Pending
                              </Option>
                              <Option value={AdmisionStatusType.TRANSFER}>
                                Transfer
                              </Option>
                              <Option value={AdmisionStatusType.CANCELLED}>
                                Cancelled
                              </Option>
                              <Option value={AdmisionStatusType.HOLD}>
                                Hold
                              </Option>
                              <Option value={AdmisionStatusType.TERMINATED}>
                                Terminated
                              </Option>
                            </Select>
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                    </Row>
                  </Form>
                </Drawer>
              </div>
            </Col>
          </Row>
          {IS_PRIVATE_ZONE ? (
            <>
              <MissingAdmissionAnalysisCard
                missingAdmissionReportStatics={missingAdmissionReportStatics}
                loading={loading}
              />
              <MissingAdmissionAnalysisTable
                missingAdmissionReportStatics={missingAdmissionReportStatics}
                loading={loading}
              />
            </>
          ) : (
            <>
              {!(IS_PRIVATE_ZONE) && (
                <BranchWiseAllMissingAdmissionReport
                  missingAdmissionReportStatics={missingAdmissionReportStatics}
                  loading={loading}
                />
              )}
            </>
          )}
      </div>
    </>
  );
};

export default MissingAdmissionAnalysis;
