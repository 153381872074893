import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { getSystemTrackerDetails } from "./systemTracker.action";
import { ISystemTrackerState } from "./syatemTracker.model";

export const initialState: ISystemTrackerState = {
    getSystemTrackerDetails: {
        loading: false,
        hasErrors: false,
        message: "",
        result:{
            data: []
        },
    }
};

export const systemTrackerSlice = createSlice({
    name: "get-pc-details",
    initialState,
    reducers: {
        clearRemoveMessage: (state) => {
            state.getSystemTrackerDetails.message = "";
        },
        clearSystemTrackerData: (state) => {
            state.getSystemTrackerDetails.result = initialState.getSystemTrackerDetails.result;
        },
    },
    extraReducers: {
        // get system tracker details
        [getSystemTrackerDetails.pending.type]: (state) => {
            state.getSystemTrackerDetails.loading = true;
        },
        [getSystemTrackerDetails.fulfilled.type]: (state, action) => {
            state.getSystemTrackerDetails.result = action.payload;
            state.getSystemTrackerDetails.message = action.payload.message;
            state.getSystemTrackerDetails.loading = false;
            state.getSystemTrackerDetails.hasErrors = false;
        },
        [getSystemTrackerDetails.rejected.type]: (state, action) => {
            state.getSystemTrackerDetails.loading = false;
            state.getSystemTrackerDetails.hasErrors = true;
            state.getSystemTrackerDetails.message = action.error.message;
        },
    },
});

// A selector
export const systemTrackerSelector = (state: RootState) => state.systemTracker;

export const { clearRemoveMessage, clearSystemTrackerData } = systemTrackerSlice.actions;

// The reducer
export default systemTrackerSlice.reducer;
