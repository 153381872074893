import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  addChequeComment,
  getInvoiceById,
  installmentPayById,
  searchInstallmentsData,
  installmentSplit,
  searchChequeListData,
} from "./installment.action";
import { IInstallmentState } from "./installment.model";

export const initialState: IInstallmentState = {
  installmentPayById: {
    loading: false,
    hasErrors: false,
    message: "",
  },

  installmentsData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      total_amount: "",
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },

  chequeListData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      total_amount: "",
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },

  getInvoiceById: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {},
  },

  addChequeComment: {
    loading: false,
    hasErrors: false,
    message: "",
  },

  installmentSplit: {
    data: {
      id: 0,
      admission_id: 0,
      no_of_installments: 0,
      installment_amount: 0,
    },
    loading: false,
    hasErrors: false,
    message: "",
  },
};

export const installmentSlice = createSlice({
  name: "installment",
  initialState,
  reducers: {
    clearRemoveMessageInstallment: (state) => {
      state.installmentPayById.message = "";
      state.getInvoiceById.message = "";
      state.installmentsData.message = "";
      state.addChequeComment.message = "";
      state.installmentSplit.message = "";
    },
  },
  extraReducers: {
    //installmentPayById
    [installmentPayById.pending.type]: (state) => {
      state.installmentPayById.loading = true;
    },
    [installmentPayById.fulfilled.type]: (state, action) => {
      state.installmentPayById.message = action.payload.message;
      state.installmentPayById.loading = false;
      state.installmentPayById.hasErrors = false;
    },
    [installmentPayById.rejected.type]: (state, action) => {
      state.installmentPayById.loading = false;
      state.installmentPayById.hasErrors = true;
      state.installmentPayById.message = action.error.message;
    },

    // searchInstallments Data
    [searchInstallmentsData.pending.type]: (state) => {
      state.installmentsData.loading = true;
    },
    [searchInstallmentsData.fulfilled.type]: (state, action) => {
      state.installmentsData.data = action.payload.data;
      state.installmentsData.message = action.payload.message;
      state.installmentsData.loading = false;
      state.installmentsData.hasErrors = false;
    },
    [searchInstallmentsData.rejected.type]: (state, action) => {
      state.installmentsData.loading = false;
      state.installmentsData.hasErrors = true;
      state.installmentsData.message = action.error.message;
    },

    // searchChequeList Data
    [searchChequeListData.pending.type]: (state) => {
      state.chequeListData.loading = true;
    },
    [searchChequeListData.fulfilled.type]: (state, action) => {
      state.chequeListData.data = action.payload.data;
      state.chequeListData.message = action.payload.message;
      state.chequeListData.loading = false;
      state.chequeListData.hasErrors = false;
    },
    [searchChequeListData.rejected.type]: (state, action) => {
      state.chequeListData.loading = false;
      state.chequeListData.hasErrors = true;
      state.chequeListData.message = action.error.message;
    },

    // getInvoice Data
    [getInvoiceById.pending.type]: (state) => {
      state.getInvoiceById.loading = true;
    },
    [getInvoiceById.fulfilled.type]: (state, action) => {
      state.getInvoiceById.data = action.payload.data;
      state.getInvoiceById.message = action.payload.message;
      state.getInvoiceById.loading = false;
      state.getInvoiceById.hasErrors = false;
    },
    [getInvoiceById.rejected.type]: (state, action) => {
      state.getInvoiceById.loading = false;
      state.getInvoiceById.hasErrors = true;
      state.getInvoiceById.message = action.error.message;
    },

    // chequeConnet Data
    [addChequeComment.pending.type]: (state) => {
      state.addChequeComment.loading = true;
    },
    [addChequeComment.fulfilled.type]: (state, action) => {
      state.addChequeComment.message = action.payload.message;
      state.addChequeComment.loading = false;
      state.addChequeComment.hasErrors = false;
    },
    [addChequeComment.rejected.type]: (state, action) => {
      state.addChequeComment.loading = false;
      state.addChequeComment.hasErrors = true;
      state.addChequeComment.message = action.error.message;
    },

    //installment split
    [installmentSplit.pending.type]: (state) => {
      state.installmentSplit.loading = true;
    },
    [installmentSplit.fulfilled.type]: (state, action) => {
      state.installmentSplit.message = action.payload.message;
      state.installmentSplit.loading = false;
      state.installmentSplit.hasErrors = false;
    },
    [installmentSplit.rejected.type]: (state, action) => {
      state.installmentSplit.loading = false;
      state.installmentSplit.hasErrors = true;
      state.installmentSplit.message = action.error.message;
    },
  },
});

// A selector
export const installmentSelector = (state: RootState) => state.installment;

export const { clearRemoveMessageInstallment } = installmentSlice.actions;

// The reducer
export default installmentSlice.reducer;
